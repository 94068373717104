import { useCallback, useEffect, useState } from 'react';
import { MarkerDragEvent } from 'react-map-gl';
import { Feature, Polygon } from '@turf/turf';
import { useApiDataActions } from 'context/actions/ApiDataActions';

import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { useFlowActions } from 'context/actions/flowActions';
import { useAppState } from 'context/AppState';
import useDrawableMap from '../../hooks/useDrawableMap';
import { useFlags } from 'launchdarkly-react-client-sdk';

import MarkersLayer from './Layers/Markers';
import BoundaryLayer from './Layers/Boundary';
import FieldsLayer from './Layers/Fields';

import useValidation from './hooks/useValidation';
import { useBreakpoint } from 'hooks';
import ProxyLayer from 'base/api/ProxyLayer';
import { OptionType } from 'context/store/flowReducer';
import { pointIsInSpecificCountry } from 'utils/helpers/geospatial';
import { CropConstants } from 'utils/constants/Crop';

const getCoordsFromEvent = (
  e: (mapboxgl.MapMouseEvent & mapboxgl.EventData) | MarkerDragEvent
): [number, number] => {
  const { lat, lng } = e.lngLat.wrap();
  return [lng, lat];
};

interface IProps {
  clickedCoordEvent?: mapboxgl.MapMouseEvent & mapboxgl.EventData;
  cursorCoordEvent?: mapboxgl.MapMouseEvent & mapboxgl.EventData;
  onChangePolygon?: (polygons: Feature<Polygon> | undefined) => void;
}

const DrawableMap = ({ clickedCoordEvent, cursorCoordEvent }: IProps) => {
  const {
    setIsDrawingBoundaries,
    setShowDrawingWarning,
    setShowInvalidBoundaryModal,
    setShowLandIsNotArableModal,
    setAreBoundariesConfirmed,
    setSaveWithoutClosingBoundary,
    setFieldToBeSaved,
    setTempFieldMapData,
    setFieldsMapData,
  } = useFlowActions();
  const {
    setIsAtleastAVertex,
    setTempBoundaryField,
    setCurrentFieldArea,
    setPreviousFieldArea,
    setFieldSnapshots,
  } = useRecommendationFormActions();
  const { flow, recommendationForm, apiData } = useAppState();
  const [fieldsHasChange, setFieldsHasChange] = useState(false);
  const { isMobile } = useBreakpoint();
  const flags = useFlags();
  const {
    currentPolygonVertexCoords,
    boundaryLineCoords,
    geoJsonMoveCoords,
    currentPolygon,
    handleOnDragMarker,
    handleMarkerClick,
    handleOnDragEndMarker,
    snapShots,
    geoJsonLastTwoCoords,
    tempPolygon,
  } = useDrawableMap({
    polygonToEdit: recommendationForm.tempBoundaryField,
    isDrawing: flow.isDrawingBoundaries,
    setIsDrawing: setIsDrawingBoundaries,
    setShowDrawingWarning,
    setShowInvalidBoundaryModal,
    clickedCoordEvent,
    cursorCoordEvent,
    handleDeleteClick: flow.deleteBoundaryAction,
    handleUndoClick: flow.undoDraw,
    handleConfirmClick: flow.areBoundariesConfirmed,
    handleEditClick: flow.editBoundaryAction,
    confirmedPolygons: recommendationForm.fields,
    doReset: flow.resetBoundaryValues,
    setCurrentFieldArea,
    setPreviousFieldArea,
    currentModeType: flow.currentModeType,
    fieldsHasChange: fieldsHasChange,
    countryCode: recommendationForm.countryCode,
    fields: recommendationForm.fields,
    isMobile,
    setTempFieldMapData,
    setFieldsMapData,
    fieldsMapData: flow.fieldsMapData,
  });
  const apiDataActions = useApiDataActions();

  const { showMarkers, showBoundaryLayer, showFieldsLayer } = useValidation({
    currentModeType: flow.currentModeType,
  });
  useEffect(() => {
    setFieldsHasChange(!fieldsHasChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendationForm.fields]);

  useEffect(() => {
    if (
      flow.saveWithoutClosingBoundary ||
      (recommendationForm.enableFieldSaveButton && isMobile && flow.saveWithoutClosingBoundary)
    ) {
      handleMarkerClick(0);
    }
  }, [flow.saveWithoutClosingBoundary, recommendationForm.enableFieldSaveButton]);

  const handleMarkerOnClick = (e: mapboxgl.MapboxEvent<MouseEvent>, index: number) => {
    e.originalEvent.stopPropagation();
    handleMarkerClick(index);
  };

  const handleOnDragMarkerEvent = (e: MarkerDragEvent, index: number) => {
    const coords = getCoordsFromEvent(e);
    handleOnDragMarker(coords, index);
  };

  const saveFirstBoundaryPointCreation = useCallback(() => {
    if (recommendationForm.fields.length > 0) {
      return;
    }
    if (currentPolygonVertexCoords.length === 1) {
      sessionStorage.setItem('FirstBoundaryPointCreation', new Date().toString());
    } else if (currentPolygonVertexCoords.length === 0) {
      sessionStorage.removeItem('FirstBoundaryPointCreation');
    }
  }, [currentPolygonVertexCoords.length, recommendationForm.fields.length]);

  useEffect(() => {
    const cancelToken = ProxyLayer.cancelToken();
    const checkIsLandArable = async () => {
      try {
        const latLng = currentPolygon?.properties?.center;
        const [longitude, latitude] = latLng;
        await apiDataActions.isLandArable(
          longitude,
          latitude,
          cancelToken.token,
          flags.isCheckLandArableEnabled
        );
      } catch (e) {
        console.log('Error!!', e);
      }
    };
    if (currentPolygon) {
      if (!isMobile) {
        checkIsLandArable();
      } else {
        setTempBoundaryField({ boundary: currentPolygon });
        if (flow.saveWithoutClosingBoundary) {
          setSaveWithoutClosingBoundary({
            saveWithoutClosingBoundary: false,
          });
          setFieldToBeSaved({ fieldToBeSaved: !flow.fieldToBeSaved });
        }
      }
    } else {
      setSaveWithoutClosingBoundary({
        saveWithoutClosingBoundary: false,
      });
      setTempBoundaryField({ boundary: undefined });
    }
    return () => {
      cancelToken?.cancel();
    };
  }, [currentPolygon]);

  useEffect(() => {
    const { arable_land } = apiData.arableLand;
    if (arable_land && currentPolygon) {
      if (isMobile) {
        setAreBoundariesConfirmed({ areBoundariesConfirmed: true });
      } else {
        const latLng = currentPolygon?.properties?.center;
        const [longitude, latitude] = latLng;
        if (
          pointIsInSpecificCountry(
            { lng: longitude, lat: latitude },
            CropConstants.SOUTH_AFRICA_CODE
          )
        ) {
          callTPPFieldForSA(latitude, longitude).then((data) => {
            if (!data) {
              return setShowDrawingWarning({ show: true });
            }
          });
        }
        setTempBoundaryField({ boundary: currentPolygon });
      }
    } else if (arable_land === false && currentPolygon) {
      setShowLandIsNotArableModal({ show: true });
    }
  }, [apiData.arableLand]);

  useEffect(() => {
    setFieldSnapshots({ snapshots: snapShots });
  }, [snapShots, setFieldSnapshots]);

  useEffect(() => {
    const isAtleastAVertex = !!currentPolygonVertexCoords.length;
    setIsAtleastAVertex({ isAtleastAVertex });
    saveFirstBoundaryPointCreation();
  }, [currentPolygonVertexCoords, setIsAtleastAVertex, saveFirstBoundaryPointCreation]);

  const callTPPFieldForSA = async (lat: number, lng: number) => {
    const tppData = await apiDataActions.getTPPForFields(
      CropConstants.SOUTH_AFRICA,
      CropConstants.WHEAT_SMALLCASE,
      [lat, lng]
    );
    return Object.keys(tppData)?.includes('spatialClass');
  };

  return (
    <div>
      {showMarkers && (
        <MarkersLayer
          areCurrentPolygon={Boolean(currentPolygon)}
          coords={currentPolygonVertexCoords}
          handleMarkerOnClick={handleMarkerOnClick}
          handleOnDragEndMarker={handleOnDragEndMarker}
          handleOnDragMarkerEvent={handleOnDragMarkerEvent}
        />
      )}

      {showBoundaryLayer && (
        <BoundaryLayer
          boundaryLineCoords={boundaryLineCoords}
          geoJsonMoveCoords={geoJsonMoveCoords}
          isDrawing={flow.isDrawingBoundaries}
          polygon={currentPolygon}
          geoJsonLastTwoCoords={geoJsonLastTwoCoords}
          isMobile={isMobile}
          tempPolygon={tempPolygon}
        />
      )}

      {showFieldsLayer && recommendationForm.fields.length > 0 && (
        <FieldsLayer
          fieldData={
            isMobile
              ? flow.fieldsMapData.features
              : recommendationForm.fields.map((item) => item.boundary)
          }
          isFieldsSaved={true}
          unSavedfield={false}
          selectedField={false}
        />
      )}
      {flow.selectedFieldMapData?.features.length > 0 && (
        <FieldsLayer
          fieldData={flow.selectedFieldMapData.features}
          isFieldsSaved={false}
          unSavedfield={false}
          selectedField={true}
        />
      )}
      {flow.digiFarmSelectedFieldMapData?.features.length > 0 && (
        <FieldsLayer
          fieldData={flow.digiFarmSelectedFieldMapData.features}
          isFieldsSaved={false}
          unSavedfield={false}
          selectedField={false}
          digifarmSelectedField={true}
        />
      )}
      {apiData.formattedDetectFieldsData?.features.length > 0 &&
        flow.optionType === OptionType.Detect && (
        <FieldsLayer
          fieldData={apiData.formattedDetectFieldsData.features.filter((item) => {
            return !flow.detectFieldSelectedIds.includes(item.properties.fieldUniqueId);
          })}
          isFieldsSaved={false}
          unSavedfield={true}
          selectedField={false}
        />
      )}
    </div>
  );
};

export default DrawableMap;
