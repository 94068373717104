import styled from 'styled-components';

export const StressLine = styled.div<{
  height?: number;
  left: number;
  width: number;
  stressValue: number;
  type: string;
  hasDottedLineAtStart: boolean;
  hasDottedLineAtEnd: boolean;
  isVisible: boolean;
}>`
  position: absolute;
  height: 100%;
  width: ${(props) => props.width}%;
  left: ${(props) => props.left}%;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  background-color: ${(props) => {
    switch (true) {
      case props.stressValue <= 3.33 && props.stressValue > 0:
        if (props.type === 'Cold') {
          return '#82CFFF40';
        } else {
          return '#F87C7C40';
        }
      case props.stressValue <= 6.66:
        if (props.type === 'Cold') {
          return '#82CFFFA6';
        } else {
          return '#F87C7C80';
        }
      case props.stressValue >= 6.67:
        if (props.type === 'Cold') {
          return '#82CFFFE5';
        } else {
          return '#F87C7CBF';
        }
    }
  }};
  &:: before {
    content: '';
    position: absolute;
    border-left: ${(props) => (props.hasDottedLineAtStart ? '1.5px dashed' : 'none')};
    height: inherit;
    border-color: ${(props) => (props.type === 'Cold' ? '#89BDF9' : '#F98989')};
  }
  &:: after {
    content: '';
    position: absolute;
    border-left: ${(props) => (props.hasDottedLineAtEnd ? '1.5px dashed' : 'none')};
    height: inherit;
    border-color: ${(props) => (props.type === 'Cold' ? '#89BDF9' : '#F98989')};
    left: 92%;
  }
`;
