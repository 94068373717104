/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';
import breakpoints from 'base/constants/breakpoints';
import { Spinner } from 'components/Spinner';
import {
  SYT_PREFIX,
  Typography,
  colors,
  Tabs as AntdTabs,
  Tooltip,
} from 'syngenta-digital-cropwise-react-ui-kit';
import { TooltipProps } from 'antd';

export const SectionContainer = styled.div`
  background-color: #f8fafe;
  &.secondary-container {
    background-color: #ffffff;
  }
`;

export const SeedDensityAdviceSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #ffffff;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  padding-top: 32px;
`;

export const SectionContent = styled.div`
  width: 100%;
  margin: 0;
  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 32px 0px;
  }
`;

export const TrialingContent = styled(SectionContent)`
  padding: 32px 0px;
  @media screen and ${breakpoints.maxWidth.sm} {
    padding: 32px 0px 32px 16px;
  }
`;

export const ProductComparisonContent = styled(SectionContent)`
  border: 1px solid #c2c7d0;
  border-radius: 16px;
  background-color: #ffffff;
  padding: 32px;
  @media screen and ${breakpoints.maxWidth.sm} {
    border-radius: 0px;
    border: 0px;
    padding: 16px;
  }
`;

export const AgronomicPreferenceContent = styled(SectionContent)`
  padding: 0;
  @media screen and ${breakpoints.minWidth.md} {
    border: 1px solid #c2c7d0;
    border-radius: 16px;
    padding: 20px;
    margin: 0 0 40px;
  }
`;

export const EnvironmentalStressContent = styled(SectionContent)`
  padding: 0;
  margin-bottom: 10px;
  @media screen and ${breakpoints.minWidth.md} {
    border: 1px solid #c2c7d0;
    border-radius: 16px;
    padding: 20px;
    margin: 0 0 40px;
  }
`;

export const Title = styled(Typography.Title)`
  font-weight: 400;
  font-size: 24px;
  line-height: 38px;
  text-align: left;
  margin-bottom: 20px;
  font-family: 'Noto Sans';
  letter-spacing: -0.0125em;

  @media screen and ${breakpoints.maxWidth.sm} {
    margin-bottom: 16px;
    font-family: Noto Sans;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.0075em;
  }
`;
export const EnvironmentalStressTitle = styled(Title)`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.0125em;
  @media screen and ${breakpoints.maxWidth.sm} {
    font-size: 16px;
    font-weight: 550;
    line-height: 24px;
    letter-spacing: -0.0075em;
  }
`;

export const ProductAdviceTitle = styled(Title)`
  font-family: Noto sans;
  font-weight: 600;
  font-size: 36px;
  margin-top: 20px;
  line-height: 44px;
  letter-spacing: -0.0175em;
  @media screen and ${breakpoints.maxWidth.sm} {
    font-size: 20px;
    font-weight: 550;
    line-height: 28px;
    letter-spacing: -0.0075em;
    padding-left: 16px;
  }
`;
export const ExpertSeedAdviceTitle = styled(Title)`
  font-family: Noto sans;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  letter-spacing: -0.0125;
  @media screen and ${breakpoints.maxWidth.sm} {
    font-size: 16px;
    font-weight: 550;
    line-height: 24px;
    letter-spacing: -0.0075em;
  }
`;

export const ProductComparisonTitle = styled(Title)`
  margin-bottom: 8px;
  line-height: 32px;
  font-size: 24px;
  font-family: noto sans;
  font-weight: 400;
  letter-spacing: -0.0125em;
  @media screen and ${breakpoints.maxWidth.sm} {
    font-size: 16px;
    font-weight: 550;
    line-height: 24px;
    letter-spacing: -0.0075em;
  }
`;

export const AgroPreferenceTitle = styled(Title)`
  margin-bottom: 10px;
  line-height: 32px;
  letter-spacing: -0.0125em;
  @media screen and ${breakpoints.maxWidth.sm} {
    font-size: 16px;
    font-weight: 550;
    line-height: 24px;
    letter-spacing: -0.0075em;
  }
`;

export const AgroWeightingsTitle = styled(Title)`
  margin-bottom: 10px;
  line-height: 32px;
`;

export const TrialingInformationTitle = styled(Title)`
  font-family: Noto Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.0125em;
  text-align: left;

  @media screen and ${breakpoints.maxWidth.sm} {
    font-size: 16px;
    font-weight: 550;
    line-height: 24px;
    letter-spacing: -0.0075em;
  }
`;

export const Subtitle = styled(Typography.Title)`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.0075em;
  margin-bottom: 24px;
  @media screen and ${breakpoints.maxWidth.sm} {
    font-size: 14px;
    line-height: 22px;
  }
`;

interface TextProps {
  center?: boolean;
}
export const Text = styled(Typography.Text)<TextProps>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  @media screen and ${breakpoints.maxWidth.sm} {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400 !important;
    letter-spacing: -0.0075em;
    color: #808080;
  }
`;

export const Tabs = styled(AntdTabs)`
  width: 100%;
  .${SYT_PREFIX}-tabs-tab-btn {
    font-size: 16px;
    &:hover {
      color: ${colors.neutral100};
    }
  }
  .${SYT_PREFIX}-tabs-tab-active .${SYT_PREFIX}-tabs-tab-btn {
    font-weight: 600;
    color: ${colors.neutral100};
  }
  .${SYT_PREFIX}-tabs-ink-bar {
    background: ${colors.blue50};
  }

  .${SYT_PREFIX}-tabs-tab + .${SYT_PREFIX}-tabs-tab {
    @media screen and ${breakpoints.maxWidth.sm} {
      margin: 0 0 0 15px;
    }
  }
`;

export const StyledSpinner = styled(Spinner)`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: inherit !important;
`;

export const FullScreenChartContainer = styled.div<{ fullScreen?: boolean }>`
  position: ${({ fullScreen }) => (fullScreen ? 'fixed' : '')};
  height: ${({ fullScreen }) => (fullScreen ? '100%' : '')};
  width: ${({ fullScreen }) => (fullScreen ? '100%' : '')};
  padding: ${({ fullScreen }) => (fullScreen ? '0 20px 20px' : '')};
  z-index: ${({ fullScreen }) => (fullScreen ? '9999' : 'unset')};
  width: 100%;

  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  overflow: auto;
`;

type TooltipCustomProps = TooltipProps & { fixedTooltipLabel?: boolean };

export const StyledTooltip = styled(Tooltip)<TooltipCustomProps>`
  & + div div {
    left: ${(props) => (props.fixedTooltipLabel ? 'unset !important;' : '')};
    right: ${(props) => (props.fixedTooltipLabel ? '21px;' : '')};
    pointer-events: none;
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 40px 0px 0px;
`;
const TooltipTextBase = styled(Typography.Text)<TextProps>`
  font-family: Noto Sans;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.075em;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TooltipTitle = styled(TooltipTextBase)`
  font-weight: 600;
`;

export const TooltipValueText = styled(TooltipTextBase)`
  font-weight: 600;
`;

export const TooltipText = styled(TooltipTextBase)`
  font-weight: 400;
`;

export const TooltipLink = styled(Typography.Text)<TextProps>`
  font-family: Noto Sans;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.075em;
  color: #0092e4;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
`;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  overflow: hidden;
  height: 650px;
  padding-top: 12px;

  @media only screen and ${breakpoints.maxWidth.sm} {
    height: 350px;
  }

  @media only screen and ${breakpoints.maxWidth.lg} and ${breakpoints.minWidth.sm} {
    height: 500px;
  }
`;

export const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  & > div {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
`;

export const IconContainer = styled.div`
  margin-bottom: 10px;
`;

export const LinkContainer = styled.span`
  color: #0071cd !important;
  cursor: pointer;
  text-decoration: underline;
`;
