export function trackGTMEvent(
  eventType: string,
  websiteCountry: string | undefined,
  userCountry: string
) {
  const envArray = ['prod'];
  if (process.env.REACT_APP_ENV && envArray.includes(process.env.REACT_APP_ENV)) {
    const currentCountry = window.sessionStorage.getItem('currentCountry');
    const websiteCountry2 = currentCountry && currentCountry !== '' ? currentCountry : websiteCountry?.toLowerCase();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventType,
      websiteCountry: websiteCountry2 || userCountry,
      userCountry: userCountry,
    });
  }
}
