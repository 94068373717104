import { RequestError } from 'base/types/Error';
import { useAppDispatch } from 'context/AppState';
import {
  FlowSteps,
  OptionType,
  ModeTypes,
  OverlappingGeometryTypes,
  TableViewProps,
  SelectedProductFieldInfo,
} from 'context/store/flowReducer';
import { useRef } from 'react';
import { BarChartLabelProps } from 'components/BarChart/BarChartLabel';
import { SeedDensityField } from 'pages/RecommendationV2/Sections/SeedDensityAdvice';
import { DetectFieldResponse } from 'base/types/detectField';
import { detectFieldFeature } from 'context/store/recommendationFormReducer';

export enum ActionTypes {
  setShowPin = 'flow/setShowPin',
  setCurrentStep = 'flow/setCurrentStep',
  setFlowStep = 'flow/setFlowStep',
  setOptionType = 'flow/optionType',
  setShowCookiesMsg = 'flow/setShowCookiesMsg',
  setShowPinInformationModal = 'flow/setShowPinInformationModal',
  setShowButtonHub = 'flow/setShowButtonHub',
  setShowTooltips = 'flow/setShowTooltips',
  setDisabledButtonHub = 'flow/setDisabledButtonHub',
  setShowMapTools = 'flow/setShowMapTools',
  setDisabledMapTools = 'flow/setDisabledMapTools',
  setIsLocationEnabled = 'flow/setIsLocationEnabled',
  setDisabledButtonNext = 'flow/setDisabledButtonNext',
  setIsDrawingBoundaries = 'flow/setIsDrawingBoundaries',
  setTempDetectFieldSelected = 'flow/setIsDetectFieldSelected',
  setIsDropAPinFlow = 'flow/setIsDropAPinFlow',
  setIsModalToBeClosed = 'flow/isModalToBeClosed',
  setShowDrawingWarning = 'flow/setShowDrawingWarning',
  setShowPinWarning = 'flow/setShowPinWarning',
  setAreBoundariesConfirmed = 'flow/setAreBoundariesConfirmed',
  setArePinConfirmed = 'flow/setArePinConfirmed',
  setShowCropTypeDrawer = 'flow/setShowCropTypeDrawer',
  setShowDeleteBoundaryModal = 'showDeleteBoundaryModal',
  setSelectedProductFieldInfo = 'flow/setSelectedProductFieldInfo',
  setIsFieldsView = 'flow/setIsFieldsView',
  setShowLayerSelectionPopUp = 'flow/setShowLayerSelectionPopUp',
  setDigiFarmDataProcessed = 'flow/setDigiFarmDataProcessed',
  setDeleteBoundaryAction = 'setDeleteBoundaryAction',
  setEditBoundaryAction = 'setEditBoundaryAction',
  setUndoDraw = 'flow/setUndoDraw',
  setShowSpinner = 'flow/setShowSpinner',
  setShowAgronomicInputs = 'flow/setShowAgronomicInputs',
  setShowGoBackHomeModal = 'flow/setShowGoBackHomeModal',
  setShowAgronomicWeightings = 'flow/setShowAgronomicWeightings',
  setShowEnvironmentDrawer = 'flow/setShowEnvironmentDrawer',
  setShowGetRecommendationModal = 'flow/showGetRecommendationModal',
  setShowUpdateInfoMessage = 'flow/showUpdateInfoMessage',
  setResetBoundaryValues = 'flow/setResetBoundaryValues',
  setFieldToBeSaved = 'flow/setFieldToBeSaved',
  setEmailRecommendationSent = 'flow/setEmailRecommendationSent',
  setShowCancelBoundaryModal = 'flow/setShowCancelBoundaryModal',
  setShowEditBoundaryModal = 'flow/setShowEditBoundaryModal',
  setShowEnvironmentInformationModal = 'flow/setShowEnvironmentInformationModal',
  setShowFieldForm = 'flow/setShowFieldForm',
  setRequestError = 'flow/setRequestError',
  setLocalCountry = 'flow/setLocalCountry',
  setIsFetching = 'flow/setIsFetching',
  setCurrentModeType = 'flow/setCurrentModeType',
  setPreviousModeType = 'flow/setPreviousModeType',
  setIsMapBounded = 'flow/setIsMapBounded',
  setMobileActiveTab = 'flow/setMobileActiveTab',
  setAppLanguage = 'recommendationForm/setAppLanguage',
  setListTabIsDisabled = 'flow/setListTabIsDisabled',
  setShowInvalidBoundaryModal = 'flow/setShowInvalidBoundaryModal',
  setEmailSentCount = 'flow/setEmailSentCount',
  setEmailUpdatedCount = 'flow/setEmailUpdatedCount',
  setEmailUpdatedList = 'flow/setEmailUpdatedList',
  setPhoneNumberUpdatedCount = 'flow/setPhoneNumberUpdatedCount',
  setEnvironmentalStressBarChartData = 'flow/setEnvironmentalStressBarChartData',
  setEnvironmentalStressTableChartProps = 'flow/setEnvironmentalStressTableChartData',
  setSeedDensityAdviceData = 'flow/setSeedDensityAdviceData',
  setDetectFieldSelectedIds = 'flow/setDetectFieldSelectedIds',
  setFieldsMapData = 'flow/setFieldsMapData',
  setTempFieldMapData = 'flow/setTempFieldMapData',
  setJourneyCompleted = 'flow/setJourneyCompleted',
  setShowPrompt = 'flow/setShowPrompt',
  setSaveWithoutClosingBoundary = 'flow/setSaveWithoutClosingBoundary',
  setAddAnother = 'flow/setAddAnother',
  setShowEnvironmentPopup = 'flow/setShowEnvironmentPopup',
  setContinueButtonActive = 'flow/setContinueButtonActive',
  setSelectedFieldMapData = 'flow/setSelectedFieldMapData',
  setIsAnotherRecommendationCreated = 'flow/setIsAnotherRecommendationCreated',
  setShowLandIsNotArableModal = 'flow/setShowLandIsNotArableModal',
  setOverlappingGeometryType = 'flow/setOverlappingGeometryType',
  setIsLongTermMobileDesign = 'flow/setIsLongTermMobileDesign',
  setShowFieldInformationSheet = 'flow/setShowFieldInformationSheet',
  setHasDetectFieldFlowStarted = 'flow/setHasDetectFieldFlowStarted',
  setUserCountryCode = 'flow/setUserCountryCode',
  setDigiFarmSelectedFieldMapData = 'flow/setDigiFarmSelectedFieldMapData',
  setAbioticStageStressType = 'flow/setAbioticStageStressType',
  setAbioticFrequencyValue = 'flow/setAbioticFrequencyValue',
  setAbioticIntensityValue = 'flow/setAbioticIntensityValue'
}

export function useFlowActions() {
  const appDispatcher = useAppDispatch();

  const actions = useRef({
    setShowPinInformationModal: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowPinInformationModal, payload });
    },
    setShowButtonHub: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowButtonHub, payload });
    },
    setDisabledButtonHub: (payload: { disabled: boolean }) => {
      appDispatcher({ type: ActionTypes.setDisabledButtonHub, payload });
    },
    setShowMapTools: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowMapTools, payload });
    },
    setDisabledMapTools: (payload: { disabled: boolean }) => {
      appDispatcher({ type: ActionTypes.setDisabledMapTools, payload });
    },
    setDisabledButtonNext: (payload: { disabled: boolean }) => {
      appDispatcher({ type: ActionTypes.setDisabledButtonNext, payload });
    },
    setIsLocationEnabled: (payload: { isEnabled: boolean }) => {
      appDispatcher({ type: ActionTypes.setIsLocationEnabled, payload });
    },
    setShowCookiesMsg: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowCookiesMsg, payload });
    },
    setOptionType: (payload: { type: OptionType }) => {
      appDispatcher({ type: ActionTypes.setOptionType, payload });
    },
    setCurrentStep: (payload: { step: FlowSteps }) => {
      appDispatcher({ type: ActionTypes.setCurrentStep, payload });
    },
    setFlowStep: (payload: { [key: string]: any; step: FlowSteps }) => {
      appDispatcher({ type: ActionTypes.setFlowStep, payload });
    },
    setShowPin: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowPin, payload });
    },
    setIsDrawingBoundaries: (payload: { isDrawing: boolean }) => {
      appDispatcher({ type: ActionTypes.setIsDrawingBoundaries, payload });
    },
    setTempDetectFieldSelected: (payload: { selected: string[] }) => {
      appDispatcher({ type: ActionTypes.setTempDetectFieldSelected, payload });
    },
    setIsDropAPinFlow: (payload: { isDropAPin: boolean }) => {
      appDispatcher({ type: ActionTypes.setIsDropAPinFlow, payload });
    },
    setIsModalToBeClosed: (payload: { isModalToBeClosed: boolean }) => {
      appDispatcher({ type: ActionTypes.setIsModalToBeClosed, payload });
    },
    setShowDrawingWarning: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowDrawingWarning, payload });
    },
    setShowPinWarning: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowPinWarning, payload });
    },
    setAreBoundariesConfirmed: (payload: { areBoundariesConfirmed: boolean }) => {
      appDispatcher({ type: ActionTypes.setAreBoundariesConfirmed, payload });
    },
    setArePinConfirmed: (payload: { arePinConfirmed: boolean }) => {
      appDispatcher({ type: ActionTypes.setArePinConfirmed, payload });
    },
    setShowCropTypeDrawer: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowCropTypeDrawer, payload });
    },
    setShowDeleteBoundaryModal: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowDeleteBoundaryModal, payload });
    },
    setSelectedProductFieldInfo: (payload: { data: SelectedProductFieldInfo[] }) => {
      appDispatcher({ type: ActionTypes.setSelectedProductFieldInfo, payload });
    },
    setIsFieldsView: (payload: { fieldView: boolean }) => {
      appDispatcher({ type: ActionTypes.setIsFieldsView, payload });
    },
    setShowLayerSelectionPopUp: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowLayerSelectionPopUp, payload });
    },
    setDigiFarmDataProcessed: (payload: { processed: boolean }) => {
      appDispatcher({ type: ActionTypes.setDigiFarmDataProcessed, payload });
    },
    setDeleteBoundaryAction: (payload: { deleteBoundaryAction: boolean }) => {
      appDispatcher({ type: ActionTypes.setDeleteBoundaryAction, payload });
    },
    setEditBoundaryAction: (payload: { editBoundaryAction: boolean }) => {
      appDispatcher({ type: ActionTypes.setEditBoundaryAction, payload });
    },
    setUndoDraw: (payload: { undoDraw: boolean }) => {
      appDispatcher({ type: ActionTypes.setUndoDraw, payload });
    },
    setShowTooltips: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowTooltips, payload });
    },
    setShowSpinner: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowSpinner, payload });
    },
    setShowAgronomicInputs: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowAgronomicInputs, payload });
    },
    setShowGoBackHomeModal: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowGoBackHomeModal, payload });
    },
    setShowAgronomicWeightings: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowAgronomicWeightings, payload });
    },
    setShowEnvironmentDrawer: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowEnvironmentDrawer, payload });
    },
    setShowGetRecommendationModal: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowGetRecommendationModal, payload });
    },
    setShowUpdateInfoMessage: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowUpdateInfoMessage, payload });
    },
    setResetBoundaryValues: (payload: { resetBoundaryValues: boolean }) => {
      appDispatcher({ type: ActionTypes.setResetBoundaryValues, payload });
    },
    setFieldToBeSaved: (payload: { fieldToBeSaved: boolean }) => {
      appDispatcher({ type: ActionTypes.setFieldToBeSaved, payload });
    },
    setRequestError: (payload: { error: RequestError }) => {
      appDispatcher({ type: ActionTypes.setRequestError, payload });
    },
    setShowCancelBoundaryModal: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowCancelBoundaryModal, payload });
    },
    setShowEditBoundaryModal: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowEditBoundaryModal, payload });
    },
    setShowEnvironmentInformationModal: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowEnvironmentInformationModal, payload });
    },
    setShowFieldForm: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowFieldForm, payload });
    },
    setEmailRecommendationSent: (payload: { sent: boolean }) => {
      appDispatcher({ type: ActionTypes.setEmailRecommendationSent, payload });
    },
    setLocalCountry: (payload: { country: string }) => {
      appDispatcher({ type: ActionTypes.setLocalCountry, payload });
    },
    setIsFetching: (payload: { isFetching: boolean }) => {
      appDispatcher({ type: ActionTypes.setIsFetching, payload });
    },

    setCurrentModeType: (payload: { modeType: ModeTypes }) => {
      appDispatcher({ type: ActionTypes.setCurrentModeType, payload });
    },
    setPreviousModeType: (payload: { modeType: ModeTypes }) => {
      appDispatcher({ type: ActionTypes.setPreviousModeType, payload });
    },
    setIsMapBounded: (payload: { bounded: boolean }) => {
      appDispatcher({ type: ActionTypes.setIsMapBounded, payload });
    },
    setMobileActiveTab: (payload: { activeTab: 'Map' | 'List' }) => {
      appDispatcher({ type: ActionTypes.setMobileActiveTab, payload });
    },
    setListTabIsDisabled: (payload: { isDisabled: boolean }) => {
      appDispatcher({ type: ActionTypes.setListTabIsDisabled, payload });
    },
    setAppLanguage: (payload: { language: string }) => {
      appDispatcher({ type: ActionTypes.setAppLanguage, payload });
    },
    setShowInvalidBoundaryModal: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowInvalidBoundaryModal, payload });
    },
    setEmailSentCount: (payload: { count: number }) => {
      appDispatcher({ type: ActionTypes.setEmailSentCount, payload });
    },
    setEmailUpdatedCount: (payload: { count: number }) => {
      appDispatcher({ type: ActionTypes.setEmailUpdatedCount, payload });
    },
    setPhoneNumberUpdatedCount: (payload: { count: number }) => {
      appDispatcher({ type: ActionTypes.setPhoneNumberUpdatedCount, payload });
    },
    setEmailUpdatedList: (payload: { list: string[] }) => {
      appDispatcher({ type: ActionTypes.setEmailUpdatedList, payload });
    },
    setStressBarChartData: (payload: { data: Partial<BarChartLabelProps[]> }) => {
      appDispatcher({ type: ActionTypes.setEnvironmentalStressBarChartData, payload });
    },
    setEnvironmentalStressTableChartProps: (payload: { props: TableViewProps }) => {
      appDispatcher({ type: ActionTypes.setEnvironmentalStressTableChartProps, payload });
    },
    setSeedDensityAdviceData: (payload: { data: SeedDensityField[] }) => {
      appDispatcher({ type: ActionTypes.setSeedDensityAdviceData, payload });
    },
    setDetectFieldSelectedIds: (payload: { data: (string | number)[] }) => {
      appDispatcher({ type: ActionTypes.setDetectFieldSelectedIds, payload });
    },
    setFieldsMapData: (payload: { data: DetectFieldResponse }) => {
      appDispatcher({ type: ActionTypes.setFieldsMapData, payload });
    },
    setSelectedFieldMapData: (payload: { data: DetectFieldResponse }) => {
      appDispatcher({ type: ActionTypes.setSelectedFieldMapData, payload });
    },
    setTempFieldMapData: (payload: { data: detectFieldFeature | undefined }) => {
      appDispatcher({ type: ActionTypes.setTempFieldMapData, payload });
    },
    setJourneyCompleted: (payload: { completed: boolean }) => {
      appDispatcher({ type: ActionTypes.setJourneyCompleted, payload });
    },
    setShowPrompt: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowPrompt, payload });
    },
    setDigiFarmSelectedFieldMapData: (payload: { data: DetectFieldResponse }) => {
      appDispatcher({ type: ActionTypes.setDigiFarmSelectedFieldMapData, payload });
    },
    setSaveWithoutClosingBoundary: (payload: { saveWithoutClosingBoundary: boolean }) => {
      appDispatcher({ type: ActionTypes.setSaveWithoutClosingBoundary, payload });
    },
    setAddAnother: (payload: { addAnother: boolean }) => {
      appDispatcher({ type: ActionTypes.setAddAnother, payload });
    },
    setShowEnvironmentPopup: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowEnvironmentPopup, payload });
    },
    setContinueButtonActive: (payload: { active: boolean }) => {
      appDispatcher({ type: ActionTypes.setContinueButtonActive, payload });
    },
    setIsAnotherRecommendationCreated: (payload: { isAnotherRecommendationCreated: boolean }) => {
      appDispatcher({ type: ActionTypes.setIsAnotherRecommendationCreated, payload });
    },
    setShowLandIsNotArableModal: (payload: { show: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowLandIsNotArableModal, payload });
    },
    setOverlappingGeometryType: (payload: { type: OverlappingGeometryTypes }) => {
      appDispatcher({ type: ActionTypes.setOverlappingGeometryType, payload });
    },
    setIsLongTermMobileDesign: (payload: { isLongTermMobileDesign: boolean }) => {
      appDispatcher({ type: ActionTypes.setIsLongTermMobileDesign, payload });
    },
    setShowFieldInformationSheet: (payload: { showFieldInformationSheet: boolean }) => {
      appDispatcher({ type: ActionTypes.setShowFieldInformationSheet, payload });
    },
    setHasDetectFieldFlowStarted: (payload: { hasDetectFieldFlowStarted: boolean }) => {
      appDispatcher({ type: ActionTypes.setHasDetectFieldFlowStarted, payload });
    },
    setUserCountryCode: (payload: { country: string }) => {
      appDispatcher({ type: ActionTypes.setUserCountryCode, payload });
    },
    setAbioticStageStressType: (payload: { stressType: string }) => {
      appDispatcher({ type: ActionTypes.setAbioticStageStressType, payload });
    },
    setAbioticFrequencyValue: (payload: { yearKey: string }) => {
      appDispatcher({ type: ActionTypes.setAbioticFrequencyValue, payload });
    },
    setAbioticIntensityValue: (payload: { yearKey: string }) => {
      appDispatcher({ type: ActionTypes.setAbioticIntensityValue, payload });
    }
  });

  return actions.current;
}
