import {
  polandCountryConstant,
  HungaryCountryConstant,
  UkraineCountryConstant,
  SouthAfricaCountryConstant,
  BulgariaCountryConstant,
  RomaniaCountryConstant,
  GermanyCountryConstant,
} from 'utils/constants/CountryData';

export const isPoland = (country: string | undefined): boolean => {
  if (
    country?.toLocaleLowerCase() === polandCountryConstant.label.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === polandCountryConstant.value.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === polandCountryConstant.id.toLocaleLowerCase()
  ) {
    return true;
  } else {
    return false;
  }
};

export const isHungary = (country: string | undefined): boolean => {
  return (
    country?.toLocaleLowerCase() === HungaryCountryConstant.label.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === HungaryCountryConstant.value.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === HungaryCountryConstant.id.toLocaleLowerCase()
  );
};

export const isUkraine = (country: string | undefined): boolean => {
  return (
    country?.toLocaleLowerCase() === UkraineCountryConstant.label.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === UkraineCountryConstant.value.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === UkraineCountryConstant.id.toLocaleLowerCase()
  );
};

export const isSouthAfrica = (country: string | undefined): boolean => {
  return (
    country?.toLocaleLowerCase() === SouthAfricaCountryConstant.label.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === SouthAfricaCountryConstant.value.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === SouthAfricaCountryConstant.id.toLocaleLowerCase()
  );
};
export const isBulgaria = (country: string | undefined): boolean => {
  return (
    country?.toLocaleLowerCase() === BulgariaCountryConstant.label.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === BulgariaCountryConstant.value.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === BulgariaCountryConstant.id.toLocaleLowerCase()
  );
};

export const isGermany = (country: string | undefined): boolean => {
  return (
    country?.toLocaleLowerCase() === GermanyCountryConstant.label.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === GermanyCountryConstant.value.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === GermanyCountryConstant.id.toLocaleLowerCase()
  );
};

export const isRomania = (country: string | undefined): boolean => {
  return (
    country?.toLocaleLowerCase() === RomaniaCountryConstant.label.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === RomaniaCountryConstant.value.toLocaleLowerCase() ||
    country?.toLocaleLowerCase() === RomaniaCountryConstant.id.toLocaleLowerCase()
  );
};
