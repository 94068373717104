import { CancelToken } from 'axios';
import ProxyLayer from 'base/api/ProxyLayer';
import { Attribute } from 'base/types/Attributes';
import {
  BreakoutsAllGlobalResponse,
  BreakoutsGlobalResponse,
} from 'base/types/BreakoutsGlobalResponse';
import { AttributesForFields, TPPForFields } from 'base/types/Fields';
import { ProductMaturityRangeFAO, ProductSeed } from 'base/types/Products';
import {
  RecCropwiseApiInputs,
  RecommendationCropwiseResponse,
} from 'base/types/RecommendationCropwise';
import { RecommendationInputResponse } from 'base/types/RecommendationInput';
import { FieldSoilRequest, SoilTypeResponse } from 'base/types/SoilType';
import { WeatherResponse } from 'base/types/Weather';
import { DownyMildewAPIResponse } from 'base/types/DownyMildew';
import { useAppDispatch, useAppState } from 'context/AppState';
import { ApiDataState } from 'context/store/ApiDataTypes';
import { useRef } from 'react';
import RetryFunc from 'utils/RetryFunc';
import {
  BreakoutsProductsSummaryDetails,
  BreakoutsProductsSummaryResponse,
} from 'base/types/BreakoutsProductsSummaryResponse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DetectFieldResponse, DetectFieldPayload } from 'base/types/detectField';
import { RecommendationPackageAPIResponse } from 'base/types/RecommendationPackageAPI';
import { UserDetails } from 'base/types/UserDetails';
import track from 'utils/amplitudeWrapper';
import { GrowthStagePayload, GrowthStagesResponse } from 'base/types/growthStage';
import { AbioticStagePayload, AbioticStressResponse } from 'base/types/AbioticStress';
import { trackGTMEvent } from 'utils/createGTMEvent';

export const API_STORE_NAME = 'common_data';

export enum ActionTypes {
  setCountries = 'ApiData/setCountries',
  setCountriesLoading = 'ApiData/setCountriesLoading',
  setCountryListError = 'ApiData/setCountryListError',

  setAttributes = 'ApiData/setAttributes',
  setAttributesLoading = 'ApiData/setAttributesLoading',
  setAttributesError = 'ApiData/setAttributesError',

  setProductMaturityRangeFAO = 'ApiData/setProductMaturityRangeFAO',
  setProductMaturityRangeFAOLoading = 'ApiData/setProductMaturityRangeFAOLoading',
  setProductMaturityRangeFAOError = 'ApiData/setProductMaturityRangeFAOError',

  setFieldSoils = 'ApiData/setFieldSoils',
  setFieldSoilsLoading = 'ApiData/setFieldSoilsLoading',
  setFieldSoilsError = 'ApiData/setFieldSoilsError',

  setRecommendation = 'ApiData/setRecommendation',
  setRecommendationLoading = 'ApiData/setRecommendationLoading',
  setRecommendationError = 'ApiData/setRecommendationError',

  setRecommendationCropwise = 'ApiData/setRecommendationCropwise',
  setRecommendationCropwiseLoading = 'ApiData/setRecommendationCropwiseLoading',
  setRecommendationCropwiseError = 'ApiData/setRecommendationCropwiseError',

  setBreakoutsGlobal = 'ApiData/setBreakoutsGlobal',
  setBreakoutsGlobalLoading = 'ApiData/setBreakoutsGlobalLoading',
  setBreakoutsGlobalError = 'ApiData/setBreakoutsGlobalError',

  setBreakoutsProductsSummary = 'ApiData/setBreakoutsProductsSummary',
  setBreakoutsProductsSummaryLoading = 'ApiData/setBreakoutsProductsSummaryLoading',
  setBreakoutsProductsSummaryError = 'ApiData/setBreakoutsProductsSummaryError',
  setBreakoutsProductsSummaryWithFilters = 'ApiData/setBreakoutsProductsSummaryWithFilters',

  setBreakoutsGlobalWithFilters = 'ApiData/setBreakoutsGlobalWithFilters',
  setBreakoutsGlobalWithFiltersLoading = 'ApiData/setBreakoutsGlobalWithFiltersLoading',
  setBreakoutsGlobalWithFiltersError = 'ApiData/setBreakoutsGlobalWithFiltersError',

  setProductCatalog = 'ApiData/setProductCatalog',
  setProductCatalogLoading = 'ApiData/setProductCatalogLoading',
  setProductCatalogError = 'ApiData/setProductCatalogError',

  setWeather = 'ApiData/setWeather',
  setWeatherLoading = 'ApiData/setWeatherLoading',
  setWeatherError = 'ApiData/setWeatherError',

  setTPPForFields = 'ApiData/setTPPForFields',
  setTPPForFieldsLoading = 'ApiData/setTPPForFieldsLoading',
  setTPPForFieldsError = 'ApiData/setTPPForFieldsError',

  setDownyMildew = 'ApiData/setDownyMildew',
  setDownyMildewLoading = 'ApiData/setDownyMildewLoading',
  setDownyMildewError = 'ApiData/setDownyMildewError',

  setRecommendationValidationLoading = 'ApiData/setRecommendationValidationLoading',
  setRecommendationValidationError = 'ApiData/setRecommendationValidationError',
  setRecommendationValidation = 'ApiData/setRecommendationValidation',

  setFieldsAttributeLoading = 'ApiData/setFieldsAttributeLoading',
  setFieldsAttributeError = 'ApiData/setFieldsAttributeError',
  setFieldsAttribute = 'ApiData/setFieldsAttribute',

  setArableLand = 'ApiData/setArableLand',
  setArableLandLoading = 'ApiData/setArableLandLoading',

  setDetectFieldLoading = 'ApiData/setDetectFieldLoading',
  setDetectFieldError = 'ApiData/setDetectFieldError',
  setDetectField = 'ApiData/setDetectField',

  setNonDDGUserDetailsLoading = 'ApiData/setNonDDGUserDetailsLoading',
  setNonDDGUserDetailsError = 'ApiData/setNonDDGUserDetailsError',
  setNonDDGUserDetails = 'ApiData/setNonDDGUserDetails',

  setGrowthStageLoading = 'ApiData/setGrowthStageLoading',
  setGrowthStageError = 'ApiData/setGrowthStageError',
  setGrowthStage = 'ApiData/setGrowthStage',

  setAbioticStageLoading = 'ApiData/setAbioticStageLoading',
  setAbioticStageError = 'ApiData/setAbioticStageError',
  setAbioticStage = 'ApiData/setAbioticStage',
}

export function getApiCache(): ApiDataState {
  return JSON.parse(window.sessionStorage.getItem(API_STORE_NAME) ?? '{}');
}

export function useApiDataActions() {
  const appDispatcher = useAppDispatch();
  const { isCheckLandArableEnabled } = useFlags();
  const {
    flow: { localCountry },
    recommendationForm: { countryCode },
  } = useAppState();

  const actions = useRef({
    getCountries: async (cache = true, country?: string, cancelToken?: CancelToken) => {
      let countryList: any[] = [];
      const apiCache = getApiCache();

      // Loading
      appDispatcher({ type: ActionTypes.setCountriesLoading, payload: { loading: true } });

      if (cache === true && apiCache?.countryList?.length > 0) {
        countryList = apiCache?.countryList;
      } else {
        try {
          // As we support multiple countries now we will fetch the country data for specific country
          // TODO: remove the fallback condition ?? if not required
          const filteredByCountry = country ?? 'ROU,PL,HUN,BRZ,DEU,UKR,BGR,ITA,ZAF';
          countryList = (await RetryFunc(async () => {
            return ProxyLayer.getCountries({
              params: { filterBy: filteredByCountry },
              cancelToken,
            });
          })) as any[];
        } catch (err) {
          // Set error
          appDispatcher({
            type: ActionTypes.setCountryListError,
            payload: { error: err },
          });
          track('API failed', { api: 'countires api' });
          return Promise.reject(err);
        }
      }
      // Set data
      appDispatcher({ type: ActionTypes.setCountries, payload: { data: countryList } });
    },
    getAttributes: async (
      crop: string | undefined,
      countryCode: string | undefined,
      cropId: string | number | undefined,
      usageGroups: string[] | undefined,
      cache = true
    ) => {
      let data: Attribute[] = [];
      const apiCache = getApiCache();

      appDispatcher({ type: ActionTypes.setAttributesLoading, payload: { loading: true } });

      const useCache =
        cache === true &&
        apiCache?.attributes?.length > 0 &&
        apiCache?.attributes?.every(
          (attr: Attribute) => attr.crop.id === cropId && attr.country.code === countryCode
        );

      if (useCache) {
        data = apiCache?.attributes;
      } else {
        try {
          data = (await RetryFunc(async () => {
            return ProxyLayer.getAttributes({ params: { crop, cropId, countryCode, usageGroups } });
          })) as Attribute[];
        } catch (err) {
          appDispatcher({ type: ActionTypes.setAttributesError, payload: { error: err } });
          track('API failed', { api: 'attributes api' });
          return Promise.reject(err);
        }
      }

      appDispatcher({ type: ActionTypes.setAttributes, payload: { data } });
    },
    getProductMaturityRangeFAO: async (
      crop: string | undefined,
      countryCode: string | undefined,
      cache = true
    ) => {
      let data: ProductMaturityRangeFAO;
      const apiCache = getApiCache();

      appDispatcher({
        type: ActionTypes.setProductMaturityRangeFAOLoading,
        payload: { loading: true },
      });

      if (
        cache === true &&
        apiCache?.productMaturityRange?.minFAO > 0 &&
        apiCache?.productMaturityRange?.maxFAO > 0
      ) {
        data = apiCache?.productMaturityRange;
      } else {
        try {
          data = (await RetryFunc(async () => {
            return ProxyLayer.getProductMaturityRangeFAO({ params: { crop, countryCode } });
          })) as ProductMaturityRangeFAO;
        } catch (err) {
          appDispatcher({
            type: ActionTypes.setProductMaturityRangeFAOError,
            payload: { error: err },
          });
          track('API failed', { api: 'product maturity range api' });
          return Promise.reject(err);
        }
      }

      appDispatcher({ type: ActionTypes.setProductMaturityRangeFAO, payload: { data } });
      return Promise.resolve(data);
    },
    getFieldSoils: async (
      fields: FieldSoilRequest[],
      cropRegion = 'CORNEAME',
      brandName = 'SYNGENTA',
      cache = true
    ) => {
      let data: SoilTypeResponse;
      const apiCache = getApiCache();

      appDispatcher({
        type: ActionTypes.setFieldSoilsLoading,
        payload: { loading: true },
      });

      if (cache === true && Object.keys(apiCache?.fieldSoils?.soilGrid).length > 0) {
        data = apiCache?.fieldSoils;
      } else {
        try {
          data = (await RetryFunc(async () => {
            return ProxyLayer.getFieldSoils({
              params: { fields, cropRegion, brandName },
            });
          })) as SoilTypeResponse;
        } catch (err) {
          appDispatcher({
            type: ActionTypes.setFieldSoilsError,
            payload: { error: err },
          });
          trackGTMEvent('service_unavailable', countryCode, localCountry);
          track('API failed', { api: 'soils api' });
          return Promise.reject(err);
        }
      }

      appDispatcher({ type: ActionTypes.setFieldSoils, payload: { data } });

      return data;
    },
    getRecommendationInput: async (
      recommendationId: string,
      { cancelToken }: { cancelToken?: CancelToken } = {}
    ) => {
      let data: RecommendationInputResponse;

      appDispatcher({
        type: ActionTypes.setRecommendationLoading,
        payload: { loading: true },
      });

      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getRecommendationInput(recommendationId, cancelToken);
        })) as RecommendationInputResponse;
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setRecommendationError,
          payload: { error: err },
        });
        track('API failed', { api: 'recommendation input api' });
        return Promise.reject(err);
      }

      appDispatcher({ type: ActionTypes.setRecommendation, payload: { data } });
      return Promise.resolve(data);
    },
    getRecommendationValidatedBeforeHand: async (body: any) => {
      let data: RecommendationPackageAPIResponse;

      appDispatcher({
        type: ActionTypes.setRecommendationValidationLoading,
        payload: { loading: true },
      });

      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getRecommendationValidated(body);
        })) as RecommendationPackageAPIResponse;
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setRecommendationValidationError,
          payload: { error: err },
        });

        trackGTMEvent('service_unavailable', countryCode, localCountry);
        track('API failed', { api: 'validate recommendation api' });
        return Promise.reject(err);
      }

      appDispatcher({ type: ActionTypes.setRecommendationValidation, payload: { data } });
      return Promise.resolve(data);
    },
    getRecommendationCropwise: async (
      input: RecCropwiseApiInputs,
      { cancelToken }: { cancelToken?: CancelToken } = {}
    ) => {
      let data: RecommendationCropwiseResponse;

      appDispatcher({
        type: ActionTypes.setRecommendationCropwiseLoading,
        payload: { loading: true },
      });

      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getRecommendationCropwise(input, cancelToken);
        })) as RecommendationCropwiseResponse;
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setRecommendationCropwiseError,
          payload: { error: err },
        });
        trackGTMEvent('service_unavailable', countryCode, localCountry);
        track('API failed', { api: 'recommendation-cropwise api' });
        return Promise.reject(err);
      }

      appDispatcher({ type: ActionTypes.setRecommendationCropwise, payload: { data } });
      return Promise.resolve(data);
    },
    getBreakoutsGlobal: async ({ productName = '', crop = '', country = '' }) => {
      let data: BreakoutsGlobalResponse;

      appDispatcher({
        type: ActionTypes.setBreakoutsGlobalLoading,
        payload: { loading: true },
      });

      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getBreakoutsGlobal({ productName, crop, country });
        })) as BreakoutsGlobalResponse;
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setBreakoutsGlobalError,
          payload: { error: err },
        });
        trackGTMEvent('service_unavailable', countryCode, localCountry);
        return Promise.reject(err);
      }

      appDispatcher({ type: ActionTypes.setBreakoutsGlobal, payload: { productName, data } });
      return Promise.resolve(data);
    },
    getBreakoutsGlobalRecommendationId: async ({
      solutionId = '',
      withFilters = false,
      activeProduct = '',
      source = '',
      decodedRecParamString = '',
    }) => {
      let data: BreakoutsAllGlobalResponse[];

      appDispatcher({
        type: ActionTypes.setBreakoutsGlobalLoading,
        payload: { loading: true },
      });

      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getBreakoutsGlobalRecommendationId({
            solutionId,
            withFilters,
            activeProduct,
            source,
            decodedRecParamString,
          });
        })) as BreakoutsAllGlobalResponse[];
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setBreakoutsGlobalError,
          payload: { error: err },
        });
        track('API failed', { api: 'Breakout global api' });
        return Promise.reject(err);
      }

      data.forEach((item: BreakoutsAllGlobalResponse) => {
        const { productName, results } = item;
        if (withFilters) {
          appDispatcher({
            type: ActionTypes.setBreakoutsGlobalWithFilters,
            payload: { productName, data: results },
          });
        } else {
          appDispatcher({
            type: ActionTypes.setBreakoutsGlobal,
            payload: { productName, data: results },
          });
        }
      });
      return Promise.resolve(data);
    },
    getBreakoutsProductsSummaryRecommendationId: async ({
      solutionId = '',
      withFilters = false,
      activeProduct = '',
      source = '',
      decodedRecParamString = '',
    }) => {
      let data: BreakoutsProductsSummaryResponse[];

      appDispatcher({
        type: ActionTypes.setBreakoutsProductsSummaryLoading,
        payload: { loading: true },
      });

      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getProductSummaryRecommendationId({
            solutionId,
            withFilters,
            activeProduct,
            source,
            decodedRecParamString,
          });
        })) as BreakoutsProductsSummaryResponse[];
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setBreakoutsProductsSummaryError,
          payload: { error: err },
        });
        trackGTMEvent('service_unavailable', countryCode, localCountry);
        track('API failed', { api: 'breakouts products summary api' });
        return Promise.reject(err);
      }
      const response: BreakoutsProductsSummaryDetails[] = [];
      data.forEach((item: BreakoutsProductsSummaryResponse) => {
        response.push(item.entrySummary[0]);
      });
      if (withFilters) {
        appDispatcher({
          type: ActionTypes.setBreakoutsProductsSummaryWithFilters,
          payload: { data: response },
        });
      } else {
        appDispatcher({
          type: ActionTypes.setBreakoutsProductsSummary,
          payload: { data: response },
        });
      }
      return Promise.resolve(data);
    },
    getBreakoutsGlobalWithFilters: async ({
      productName = '',
      crop = '',
      country = '',
      yieldLevel = '',
      texture = '',
    }) => {
      let data: BreakoutsGlobalResponse;

      appDispatcher({
        type: ActionTypes.setBreakoutsGlobalWithFiltersLoading,
        payload: { loading: true },
      });

      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getBreakoutsGlobal({ productName, crop, country, yieldLevel, texture });
        })) as BreakoutsGlobalResponse;
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setBreakoutsGlobalWithFiltersError,
          payload: { error: err },
        });
        track('API failed', { api: 'breakout global with filters api' });
        return Promise.reject(err);
      }

      appDispatcher({
        type: ActionTypes.setBreakoutsGlobalWithFilters,
        payload: { productName, data },
      });
      return Promise.resolve(data);
    },

    getProductCatalog: async (crop: string, countryCode: string, cancelToken?: CancelToken) => {
      let data: ProductSeed[];

      appDispatcher({
        type: ActionTypes.setProductCatalogLoading,
        payload: { loading: true },
      });

      try {
        data = (await RetryFunc(async () => {
          return await ProxyLayer.getProducts({
            params: { crop, countryCode },
            cancelToken,
          });
        })) as ProductSeed[];
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setProductCatalogError,
          payload: { error: err },
        });
        track('API failed', { api: 'product catalog api' });
        return Promise.reject(err);
      }

      appDispatcher({ type: ActionTypes.setProductCatalog, payload: { data } });
      return Promise.resolve(data);
    },
    getWeather: async (lat: number, lng: number, startDate: string, endDate: string) => {
      let data: WeatherResponse[];

      appDispatcher({
        type: ActionTypes.setWeatherLoading,
        payload: { loading: true },
      });

      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getWeather({ lat, lng, startDate, endDate });
        })) as WeatherResponse[];
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setWeatherError,
          payload: { error: err },
        });
        track('API failed', { api: 'weather api' });
        return Promise.reject(err);
      }

      appDispatcher({ type: ActionTypes.setWeather, payload: { data } });
      return Promise.resolve(data);
    },
    getTPPForFields: async (
      countryCode: string | undefined,
      cropId: string | number | undefined,
      centroid: number[] | []
    ) => {
      let data: TPPForFields;
      appDispatcher({ type: ActionTypes.setTPPForFieldsLoading, payload: { loading: true } });

      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getTPPForFields({ params: { cropId, countryCode, centroid } });
        })) as TPPForFields;
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setTPPForFieldsError,
          payload: { error: err },
        });
        track('API failed', { api: 'tpp zone api' });
        return Promise.reject(err);
      }

      appDispatcher({ type: ActionTypes.setTPPForFields, payload: { data } });
      return Promise.resolve(data);
    },
    getDownyMildew: async (
      tppRegion: string,
      cropRotation: string,
      tillageSystem: string,
      soilType: string
    ) => {
      let data: DownyMildewAPIResponse;
      appDispatcher({
        type: ActionTypes.setDownyMildewLoading,
        payload: { loading: true },
      });

      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getDownyMildew({
            params: { tppRegion, cropRotation, tillageSystem, soilType },
          });
        })) as DownyMildewAPIResponse;
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setDownyMildewError,
          payload: { error: err },
        });
        trackGTMEvent('service_unavailable', countryCode, localCountry);
        track('API failed', { api: 'Downy mildew api' });
        return Promise.reject(err);
      }

      const filters: string[] = ['m0_filter', 'm3_filter', 'm4_filter', 'm9_filter'];

      const value: string[] = filters
        .filter((filter: string) => (data as any)?.[filter])
        .map((filter: string) => filter.slice(0, 2).toUpperCase());
      const response = { value, dm_risk: data.dm_risk };
      appDispatcher({
        type: ActionTypes.setDownyMildew,
        payload: { data: response },
      });

      return Promise.resolve(response);
    },
    getFieldAttributes: async (
      country: string | undefined,
      cropId: string | number | undefined,
      long: number,
      lat: number
    ) => {
      let data: AttributesForFields;
      appDispatcher({ type: ActionTypes.setFieldsAttributeLoading, payload: { loading: true } });

      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getFieldsAttributes({ params: { country, cropId, long, lat } });
        })) as AttributesForFields;
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setFieldsAttributeError,
          payload: { error: err },
        });
        track('API failed', { api: 'Field attributes api' });
        return Promise.reject(err);
      }

      appDispatcher({ type: ActionTypes.setFieldsAttribute, payload: { data } });
      return Promise.resolve(data);
    },
    isLandArable: async (
      lng: number,
      lat: number,
      cancelToken?: CancelToken,
      isCheckLandArableEnabledFlag?: boolean
    ) => {
      let data = { arable_land: true };
      isCheckLandArableEnabledFlag = isCheckLandArableEnabledFlag ?? isCheckLandArableEnabled;
      if (!isCheckLandArableEnabledFlag) {
        appDispatcher({ type: ActionTypes.setArableLand, payload: { data } });
        return data;
      }
      appDispatcher({
        type: ActionTypes.setArableLandLoading,
        payload: { loading: true },
      });

      try {
        data = (await RetryFunc(async () => {
          return await ProxyLayer.verifyLandArable(lat, lng, cancelToken);
        })) as { arable_land: boolean };
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setArableLand,
          payload: { data },
        });
        track('API failed', { api: 'arable Land api' });
        return Promise.reject(err);
      }
      appDispatcher({ type: ActionTypes.setArableLand, payload: { data } });
      return Promise.resolve(data);
    },
    detectFields: async (detectFieldPayload: DetectFieldPayload, cancelToken?: CancelToken) => {
      let data: DetectFieldResponse;
      appDispatcher({ type: ActionTypes.setDetectFieldLoading, payload: { loading: true } });

      try {
        data = (await RetryFunc(async () => {
          return await ProxyLayer.checkAvailableFields(detectFieldPayload, cancelToken);
        })) as DetectFieldResponse;
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setDetectFieldError,
          payload: { error: err },
        });
        track('API failed', { api: 'detect field api' });
        return Promise.reject(new Error('Error in Detect Field data'));
      }
      if (data?.features?.length) {
        track('boundaries detected', { 'detect field boundary available': true });
        appDispatcher({
          type: ActionTypes.setDetectField,
          payload: { formattedDetectField: data },
        });
      } else {
        appDispatcher({ type: ActionTypes.setDetectFieldLoading, payload: { loading: false } });
      }
      return Promise.resolve(data);
    },
    digifarmHighResolutionFields: async (payload: {fields: string[], countryCode: string}) => {
      let data: DetectFieldResponse;

      try {
        data = (await RetryFunc(async () => {
          return await ProxyLayer.getHighResolutionFields(payload);
        })) as DetectFieldResponse;
      } catch (err) {
        track('API failed', { api: 'digifarm high resolution api failed' });
        return Promise.reject(new Error('Error in digifarm high resolution data'));
      }
      return Promise.resolve(data);
    },
    getTokenApi: async () => {
      let data: any;
      try {
        data = (await RetryFunc(async () => {
          return await ProxyLayer.getToken();
        })) as any;
      } catch (err) {
        console.log(err);
      }
      return data;
    },
    getUserDetails: async (email: string, cancelToken: CancelToken) => {
      let data: UserDetails[];
      appDispatcher({
        type: ActionTypes.setNonDDGUserDetailsLoading,
        payload: { loading: true },
      });
      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getNonDDGUserDetails(email, cancelToken);
        })) as UserDetails[];
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setNonDDGUserDetailsError,
          payload: { error: err },
        });
        track('API failed', { api: 'user details api' });
        return Promise.reject(new Error('Error in fetching the non ddg user details'));
      }

      appDispatcher({ type: ActionTypes.setNonDDGUserDetails, payload: { data } });
      return Promise.resolve(data);
    },
    getGrowthStageDetails: async (
      requestBodyGrowthStage: GrowthStagePayload,
      cancelToken: CancelToken
    ) => {
      let data: GrowthStagesResponse;

      appDispatcher({
        type: ActionTypes.setGrowthStageLoading,
        payload: { loading: true },
      });

      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getGrowthStage(requestBodyGrowthStage, cancelToken);
        })) as GrowthStagesResponse;
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setGrowthStageError,
          payload: { error: err },
        });
        return Promise.reject(new Error('Error in fetching the growth stage details'));
      }
      const hasError = Array.isArray(data) && data.some((item) => item.error);

      if (hasError) {
        // If there's an error, dispatch an action to set the error state
        appDispatcher({
          type: ActionTypes.setGrowthStageError,
          payload: { error: 'One or more growth stage requests failed' },
        });
        // You can also return a rejected promise here if you want to handle it in the caller
        return Promise.reject(new Error('One or more growth stage requests failed'));
      } else {
        appDispatcher({ type: ActionTypes.setGrowthStage, payload: { data } });
        return Promise.resolve(data);
      }
    },
    getAbioticStageDetails: async (
      requestBodyAbioticStage: AbioticStagePayload,
      cancelToken: CancelToken
    ) => {
      let data: AbioticStressResponse;

      appDispatcher({
        type: ActionTypes.setAbioticStageLoading,
        payload: { loading: true },
      });

      try {
        data = (await RetryFunc(async () => {
          return ProxyLayer.getAbioticStage(requestBodyAbioticStage, cancelToken);
        })) as AbioticStressResponse;
      } catch (err) {
        appDispatcher({
          type: ActionTypes.setAbioticStageError,
          payload: { error: err },
        });
        return Promise.reject(new Error('Error in fetching the abiotic stage details'));
      }
      if (data?.error) {
        appDispatcher({
          type: ActionTypes.setAbioticStageError,
          payload: { error: 'Error in fetching the abiotic stage details' },
        });
        return Promise.reject(new Error('Error in fetching the abiotic stage details'));
      }
      appDispatcher({ type: ActionTypes.setAbioticStage, payload: { data } });
      return Promise.resolve(data);
    },
  });

  return actions.current;
}
