import { ButtonType, Col, colors } from 'syngenta-digital-cropwise-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { ModalAnimationDirections } from 'base/types/ModalBox';
import { useBreakpoint } from 'hooks';

import IconWarning from 'components/Icons/IconWarning';
import {
  StyledButton,
  StyledParagraph,
  StyledButtonRow,
  StyledModalView,
  StyledLeftDiv,
  StyledRightDiv,
  StyledTitle,
  StyledContent,
} from './RelocatePositionModal.styles';
import { useEffect, useState } from 'react';
import { trackGTMEvent } from 'utils/createGTMEvent';
import { useAppState } from 'context/AppState';

interface Props {
  title?: string;
  text?: string;
  notificationColor?: string;
  onOkClick: Function;
}

const RelocatePositionModal = ({
  title,
  text,
  notificationColor = colors.yellow40,
  onOkClick,
}: Props): JSX.Element => {
  const [t] = useTranslation();
  const { isMobile } = useBreakpoint();

  const [slidingDirection, setSlidingDirection] = useState<ModalAnimationDirections | undefined>(
    isMobile ? ModalAnimationDirections.Up : undefined
  );

  const {
    flow: {localCountry},
    recommendationForm: { countryCode },
  } = useAppState();

  useEffect(() => {
    trackGTMEvent('no_product_available', countryCode, localCountry);
  }, []);
  const onHandleClick = () => {
    if (isMobile) {
      setSlidingDirection(ModalAnimationDirections.Down);
    } else {
      onOkClick();
    }
  };

  const handleAnimationEnd = () => {
    if (!isMobile || slidingDirection === ModalAnimationDirections.Up) {
      return;
    }
    onOkClick();
  };

  return (
    <StyledModalView
      data-testid="relocate-modal"
      isClosable={false}
      width={470}
      isViewFooter={isMobile}
      isNotificationFooter={isMobile}
      notificationColor={notificationColor}
      content={
        <StyledContent>
          {!isMobile && (
            <StyledLeftDiv>
              <IconWarning color={colors.yellow40} width={24} height={24} />
            </StyledLeftDiv>
          )}
          <StyledRightDiv>
            <StyledTitle>{title || 'Title goes here'}</StyledTitle>
            <StyledParagraph>
              {text ||
                'Lorem ipsum dolor sit amet, 1:1 consectetur adipiscing elit, sed do eiusmod. '}
            </StyledParagraph>
          </StyledRightDiv>
        </StyledContent>
      }
      footer={
        <StyledButtonRow>
          <Col span={24}>
            <StyledButton
              data-testid="relocate-modal-ok-button"
              type={ButtonType.primary}
              onClick={onHandleClick}
            >
              {t('Ok')}
            </StyledButton>
          </Col>
        </StyledButtonRow>
      }
      slideDirection={slidingDirection}
      onAnimationEnd={handleAnimationEnd}
    />
  );
};

export default RelocatePositionModal;
