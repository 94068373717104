import React from 'react';
import { Tab, TabsContainer } from './SectionTabs.styles';
import { useTranslation } from 'react-i18next';
import { isUkraine } from 'utils/countryCode';
import { InputAndDiagnosticsTabName, RecommendationTabName } from 'utils/constants/RecommendationSections';

export type SectionTab = 'Recommendation' | 'InputAndDiagnostics';

interface SectionTabsProps {
  selectedTab: SectionTab;
  handleTabClick: (tab: SectionTab) => void;
  isDDG?: boolean;
  countryCode?: string;
}

const SectionTabs: React.FC<SectionTabsProps> = ({
  selectedTab,
  handleTabClick,
  isDDG,
  countryCode,
}) => {
  const isTabsSticky = !isDDG || isUkraine(countryCode);
  const [t] = useTranslation();
  return (
    <TabsContainer isTabsSticky={isTabsSticky}>
      <Tab
        className={selectedTab === RecommendationTabName ? 'selected' : ''}
        onClick={() => handleTabClick(RecommendationTabName)}
        data-testid="recommendation-tab"
      >
        {t('Recommendation Tab')}
      </Tab>
      <Tab
        className={selectedTab === InputAndDiagnosticsTabName ? 'selected' : ''}
        onClick={() => handleTabClick(InputAndDiagnosticsTabName)}
        data-testid="input-and-diagnostics-tab"
      >
        {t('Diagnostics')}
      </Tab>
    </TabsContainer>
  );
};

export default SectionTabs;
