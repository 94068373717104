/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import track from 'utils/amplitudeWrapper';
import {
  TableStyled,
  TrStyled,
  RankText,
  VarietyText,
  TrExpandableRow,
  TableContainer,
  ExpandedTdStyled,
  NumberText,
  CommonText,
  DescriptionText,
  SkuContainer,
} from './DensityDemandTableMobile.styles';
import { useTranslation } from 'react-i18next';
import { IconChevronRightStyled, TdMainRow } from '../DensityDemandTable/styles';
import { SeedDensityField } from '../SeedDensityAdvice';
import { RankedSeed } from 'base/types/RecommendationCropwise';
import { numberFormat, numberToEuropeanFormat } from 'utils/numberFormat';
import FieldCeil from '../FieldCeilComponent';
import { useAppState } from 'context/AppState';
import { CropConstants } from 'utils/constants/Crop';
import { convertSeedsToBagsForProduct } from 'utils/convertSeedsToBagsForProduct';
import generateSeedDensityAdviceTableData from 'utils/getDataForSeedDensityAdviceTable';

type TSeedDemand = {
  rank: number | string;
  variety: string;
  seeds: string | number;
  plantingArea: string | number;
  numberOfBags: string | number;
};

type SkuDataItem = {
  commercial_name: string;
  sku_description: string;
};

interface IProps {
  dataSource: SeedDensityField[];
  isNonDDG: boolean;
  recommendationAssignments?: any;
  countryCode: string;
}

export type TData = {
  fieldName: string;
  fieldCoordinates: number[][][];
  seedDemand: TSeedDemand[];
  area: string | number;
  expanded?: boolean;
};

const MainRow = ({
  data,
  handleExpandableRowClick,
}: {
  data: SeedDensityField;
  isNonDDG?: boolean;
  handleExpandableRowClick: (rowData: SeedDensityField) => void;
}) => {
  return (
    <TrStyled>
      <td>
        <FieldCeil {...data} />
      </td>
      <TdMainRow data-testid="seedDensityTableChevronIconMobile" hasPaddingRight={true}>
        <IconChevronRightStyled
          isOpened={data.expanded}
          onClick={() => handleExpandableRowClick(data)}
        />
      </TdMainRow>
    </TrStyled>
  );
};

const ExpandableRow = ({
  data,
  isNonDDG,
  configRecommendation,
  field,
  countryCode,
  getSkuDescription,
}: {
  data: RankedSeed;
  isNonDDG: boolean;
  configRecommendation: any;
  countryCode: string;
  field: string;
  getSkuDescription: (varietyName: string) => string;
}) => {
  const [t] = useTranslation();
  const {
    apiData: { productCatalog },
  } = useAppState();
  const getExpandableRowValueForNonDDG = (key: string, data: RankedSeed) => {
    const seedName = data.Seed?.SeedName;

    const getSeedRate = () => {
      if (data && (data.Seed || data.SeedName)) {
        const calculatedSeedRates = configRecommendation
          .find((item: any) => item.FieldId === field)
          ?.CalculatedSeedRate?.reduce((acc: any, item: any) => {
            return { ...acc, ...item };
          }, {});

        return calculatedSeedRates?.[data?.Seed?.SeedName ?? data.SeedName];
      }
      return 0;
    };

    const plantingArea = (key: string) => {
      return Number((data[key as unknown as keyof RankedSeed] as number)?.toFixed(2));
    };
    if (key === 'sku') {
      return '';
    } else if (key === 'numberOfBags') {
      const bags = Math.ceil(Number(data?.numberOfBags));
      if (!bags && data.Seed) {
        const product = productCatalog.find(
          (item: any) => item.productName === seedName || item.commercialName === seedName
        );
        return Math.ceil(
          convertSeedsToBagsForProduct(getSeedRate(), plantingArea('plantingArea'), product)
        );
      }
      return bags;
    } else if (key === 'seeds') {
      return numberFormat(data[key as unknown as keyof RankedSeed] as number);
    } else if (key === 'plantingArea') {
      return plantingArea(key);
    } else if (key === 'plants') {
      return data?.plants;
    } else if (typeof data[key as keyof RankedSeed] === 'number') {
      return numberToEuropeanFormat(data[key as keyof RankedSeed] as number) || '';
    } else {
      return (data[key as keyof RankedSeed] as number) || '';
    }
  };

  return (
    <TrExpandableRow>
      <ExpandedTdStyled>
        <RankText>{data.rank}</RankText>
        <VarietyText>{data.variety}</VarietyText>
      </ExpandedTdStyled>
      <ExpandedTdStyled>
        {countryCode === CropConstants.SOUTH_AFRICA_CODE ? (
          <CommonText>{t('kg/ha')}</CommonText>
        ) : (
          <CommonText>{t('seeds/ha')}</CommonText>
        )}{' '}
        <NumberText>
          {isNonDDG
            ? getExpandableRowValueForNonDDG('seeds', data)
            : numberFormat(data.seeds as number)}
        </NumberText>
      </ExpandedTdStyled>
      <ExpandedTdStyled>
        <CommonText>{t('Area (ha)')}</CommonText>
        <NumberText>
          {isNonDDG
            ? getExpandableRowValueForNonDDG('plantingArea', data)
            : (data?.plantingArea as number)?.toFixed(2)}
        </NumberText>
      </ExpandedTdStyled>
      <ExpandedTdStyled>
        <CommonText>{t('Bags')}</CommonText>
        <NumberText>
          {isNonDDG
            ? getExpandableRowValueForNonDDG('numberOfBags', data)
            : Math.ceil(Number(data?.numberOfBags))}
        </NumberText>
      </ExpandedTdStyled>
      {countryCode === CropConstants.SOUTH_AFRICA_CODE && (
        <ExpandedTdStyled>
          <CommonText>{t('Bag size')}</CommonText>
          <NumberText>{numberToEuropeanFormat(data.bagSize as number)}</NumberText>
        </ExpandedTdStyled>
      )}
      {countryCode !== CropConstants.SOUTH_AFRICA_CODE && (
        <ExpandedTdStyled>
          <CommonText>{t('Plants/m2')}</CommonText>
          <NumberText>{data.plants as number}</NumberText>
        </ExpandedTdStyled>
      )}
      {isNonDDG && (
        <SkuContainer>
          <ExpandedTdStyled>
            <CommonText>{t('SKU')}</CommonText>
          </ExpandedTdStyled>
          <ExpandedTdStyled>
            <DescriptionText className="description">
              {getSkuDescription(data.variety) ?? 'N/A'}
            </DescriptionText>
          </ExpandedTdStyled>
        </SkuContainer>
      )}
    </TrExpandableRow>
  );
};

const DensityDemandTableMobile = ({
  dataSource,
  isNonDDG,
  countryCode,
  recommendationAssignments,
}: IProps) => {
  const [dataToDisplay, setDataToDisplay] = useState<SeedDensityField[]>([]);
  const [skuData, setSkuData] = useState<SkuDataItem[]>([]);
  const {
    apiData: { recommendationCropwise, productCatalog },
  } = useAppState();

  const configRecommendation =
    recommendationCropwise?.recommendations[0].multi_field.config.recommendation;

  const getSKUForNonDDGSolutions = (productName: string) => {
    const recommendedProduct = productCatalog?.find((item) => item.commercialName === productName);
    const Sku = recommendedProduct?.sku.find((sku) => sku.available && sku?.defaultSku);
    return Sku?.description ?? 'N/A';
  };

  const getSkuDescription = (varietyName: string) => {
    const matchedSku = skuData.find(
      (sku) => sku.commercial_name?.toLowerCase() === varietyName?.toLowerCase()
    );
    return matchedSku ? matchedSku.sku_description : getSKUForNonDDGSolutions(varietyName);
  };

  useEffect(() => {
    if (dataSource.length) {
      let updatedDataSource = [];
      if (isNonDDG) {
        updatedDataSource = generateSeedDensityAdviceTableData(
          recommendationAssignments,
          dataSource,
          productCatalog
        );
      } else {
        updatedDataSource = dataSource.map((field: any, index: number) => ({
          ...field,
          expanded: index === 0,
        }));
      }
      setDataToDisplay(updatedDataSource);
    }
  }, [dataSource]);

  useEffect(() => {
    if (recommendationCropwise?.recommendations?.length) {
      const skuDataFetch = recommendationCropwise.recommendations[0].products.map((product) => ({
        commercial_name: product.commercial_name,
        sku_description:
          (product.sku?.description || getSKUForNonDDGSolutions(product.commercial_name)) ?? '',
      }));
      setSkuData(skuDataFetch);
    }
  }, [recommendationCropwise]);

  const handleExpandableRowClick = (rowData: SeedDensityField) => {
    const dataToDisplayCopy = [...dataToDisplay].map((row) => {
      if (row.name === rowData.name) {
        return {
          ...row,
          expanded: !row.expanded,
        };
      }
      return row;
    });
    setDataToDisplay(dataToDisplayCopy);
  };

  const renderExpandableRow = (field: SeedDensityField) => {
    let seedsDemand = [...field.seedDemand];
    if (!isNonDDG) {
      seedsDemand = seedsDemand.filter((seedDemand) => {
        const rank =
          typeof seedDemand.rank === 'string' ? parseInt(seedDemand.rank) : seedDemand.rank;
        return rank < 4;
      });
    } // We only need the first 3 ranks
    return (
      <>
        <MainRow
          data={field}
          isNonDDG={isNonDDG}
          handleExpandableRowClick={handleExpandableRowClick}
        />
        {field.expanded &&
          seedsDemand.map((seed) => (
            <ExpandableRow
              key={seed.rank}
              data={seed}
              isNonDDG={isNonDDG}
              countryCode={countryCode}
              field={field.FieldId}
              configRecommendation={configRecommendation}
              getSkuDescription={getSkuDescription}
            />
          ))}
      </>
    );
  };

  return (
    <TableContainer>
      <TableStyled data-testid="seedDensityAdviceTableMobile">
        <tbody>
          {dataToDisplay.map((densityDemandInfo) => renderExpandableRow(densityDemandInfo))}
        </tbody>
      </TableStyled>
    </TableContainer>
  );
};

export default DensityDemandTableMobile;
