/* eslint-disable array-bracket-newline */
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'hooks';
import { useEffect, useState, useCallback } from 'react';

import {
  StyledSider,
  StyledParagraph,
  StyledRow,
  StyledCol,
  StyledTitle,
  StyledContainer,
  StyledDivider,
  StyledButtonGroupNew,
  SpinnerContainer,
} from './SelectCropDrawer.styles';

import CropTypeIcons from 'components/CropTypeIcons/CropTypeIcons';
import AgronomicsInputsSider from 'components/AgronomicsInputsSider';
import { useAppState } from 'context/AppState';

import CropCard, { CropCardProps } from './CropCard';

import { Country, Crop } from 'base/types/Countries';
import { CropConstants, ExcludedCropList } from 'utils/constants/Crop';

import { ICrop } from 'base/types/Crop';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { StyledSpinner } from 'components/Spinner/Spinner.styles';
import { SUNFLOWER_ENABLED_COUNTRIES } from 'utils/constants/CountryData';
import { trackGTMEvent } from 'utils/createGTMEvent';

export interface IProps {
  title: string;
  description: string;
  onClickPrev?: () => void;
  onClickNext?: () => void;
  countryCode?: string;
  cropId?: number;
  areNewBoundaryChages?: boolean;
  countryList: Country[];
  handleSelectedCropChange: (selectedCrop: ICrop) => void;
  handleResetCropsDataFromAPI: () => void;
  isLoading?: boolean;
}

const Drawer = ({
  title,
  description,
  onClickPrev,
  onClickNext,
  countryCode,
  countryList,
  cropId,
  handleSelectedCropChange,
  handleResetCropsDataFromAPI,
  isLoading,
}: IProps): JSX.Element => {
  const [t] = useTranslation();
  const recommendationFormActions = useRecommendationFormActions();
  const [localCrops, setLocalCrops] = useState<CropCardProps[]>([]);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const { isMobile } = useBreakpoint();
  const { GRAIN_CORN, CORN_SMALLCASE } = CropConstants;
  const {
    flow: { localCountry },
  } = useAppState();
  //TODO: Temporarily removed isSunflowerEnabled from launch darkly featureflag
  const isSunflowerEnabled = countryCode
    ? SUNFLOWER_ENABLED_COUNTRIES.includes(countryCode?.toUpperCase() || '')
    : true;
  const getIfSunflowerIsEnabled = (cropName: string) => {
    if (cropName.toLowerCase().includes('sunflower') && isSunflowerEnabled) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    trackGTMEvent('select_crop_screen_visible', countryCode, localCountry);
  }, []);

  useEffect(() => {
    const country = countryList?.find((item: Country) => item.shortCode === countryCode);
    const unsortedCrops =
      country?.crops?.map?.((item: Crop) => {
        const iconName = item.CropName?.toLowerCase();
        const isCorn = item.CropName.toLowerCase().includes(CropConstants.CORN_SMALLCASE);
        const isWheat = item.CropName.toLowerCase().includes(CropConstants.WHEAT_SMALLCASE);
        const isOtherCornInBrazil =
          countryCode === CropConstants.BRAZIL_CODE &&
          item.CropName === CropConstants.CORN_SMALLCASE;
        const cropType = item.CropTypes ? item.CropTypes[0].label : iconName;
        const isSunflowerEnabledForCountry = getIfSunflowerIsEnabled(item.CropName);
        const isCropDisabled = () => {
          if (isOtherCornInBrazil) {
            return false;
          } else if (isCorn || isWheat) {
            return true;
          }
          return isSunflowerEnabledForCountry;
        };
        return {
          id: item.id,
          icon: CropTypeIcons[iconName] || CropTypeIcons.corn,
          name: item.CropName,
          isSelected: item.id === cropId,
          disabled: isCropDisabled(),
          isCorn,
          type: cropType,
        };
      }) ?? [];

    // Corn crops should be first in the list
    const corns = unsortedCrops.filter((item: any) => item.isCorn);
    const notCorns = unsortedCrops.filter(
      (item: any) => !item.isCorn && !ExcludedCropList.includes(item.name)
    );
    setLocalCrops([...corns, ...notCorns]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode, countryList, cropId, isLoading]);

  const onSelectCrop = useCallback(
    (cropName: string, localCropId: number, cropType: string) => {
      handleResetCropsDataFromAPI();
      const selectedCrop: ICrop = { id: localCropId, name: cropName, type: cropType };
      handleSelectedCropChange(selectedCrop);
      setLocalCrops((prev) =>
        prev.map((item) => {
          return {
            ...item,
            isSelected: item.name === cropName,
          };
        })
      );

      recommendationFormActions.setProductMadurityRange({
        productMadurityRange: [],
      });
      trackGTMEvent('select_crop', countryCode, localCountry);
    },
    [handleResetCropsDataFromAPI, handleSelectedCropChange, recommendationFormActions]
  );

  const handleOnCollapsibleClick = useCallback(() => {
    if (!cropId) {
      return;
    }
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed, cropId]);

  const renderLocalCrops = useCallback(() => {
    return localCrops.map(({ name, icon, isSelected, disabled, id, type }, idx) => {
      const updatedName = name === CORN_SMALLCASE ? GRAIN_CORN : name;
      const namesArray = updatedName.split(' ');
      const capitalizedName = namesArray
        .map((singleName) => {
          return singleName[0].toUpperCase() + singleName.slice(1);
        })
        .join(' ');

      return (
        <StyledCol
          key={`crop-${idx}`}
          onClick={() => onSelectCrop(name, id, type || '')}
          style={{ pointerEvents: !disabled ? 'none' : 'auto' }}
        >
          <CropCard
            id={id}
            name={t(capitalizedName)}
            icon={icon}
            isSelected={isSelected}
            disabled={!disabled}
            tag={disabled ? undefined : t('Coming Soon')}
          />
        </StyledCol>
      );
    });
  }, [localCrops, onSelectCrop, t]);

  const agronomicInputsSection = () => {
    if (!cropId) {
      return <></>;
    }

    return isLoading ? (
      <SpinnerContainer>
        <StyledSpinner data-testid="agroInputSpinner" />
      </SpinnerContainer>
    ) : (
      <>
        <StyledDivider />
        <AgronomicsInputsSider
          handleOnCollapsibleClick={handleOnCollapsibleClick}
          collapsibleActiveKey={isCollapsed ? ['0'] : ['1']}
          isMobile={isMobile}
        />
      </>
    );
  };

  return (
    <StyledSider width={isMobile ? '100%' : 375}>
      {localCrops.length === 0 ? (
        <SpinnerContainer>
          <StyledSpinner data-testid="cropSpinner" />
        </SpinnerContainer>
      ) : (
        <StyledContainer>
          <StyledTitle level={3}>{title}</StyledTitle>
          <StyledParagraph>{description}</StyledParagraph>

          <StyledRow data-testid="crops-container">{renderLocalCrops()}</StyledRow>
          {agronomicInputsSection()}

          <StyledButtonGroupNew
            buttonLeft={{
              onClick: onClickPrev,
            }}
            buttonRight={{
              onClick: onClickNext,
            }}
          />
        </StyledContainer>
      )}
    </StyledSider>
  );
};

export default Drawer;
