import { DatePickerProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { DatePicker as DatePick, colors } from 'syngenta-digital-cropwise-react-ui-kit';
import IconLastPage from 'components/Icons/IconLastPage';
import IconFirstPage from 'components/Icons/IconFirstPage';
import IconKeyboardArrowLeft from 'components/Icons/IconKeyboardArrowLeft';
import IconKeyboardArrowRight from 'components/Icons/IconKeyboardArrowRight';
import { datePickerMapLocales } from './DatePickerLocales';
import './datePicker.styles.less';

const DatePicker = (props: DatePickerProps): JSX.Element => {
  const { i18n } = useTranslation();
  return (
    <DatePick
      {...props}
      prevIcon={<IconKeyboardArrowLeft width={9} height={9} color={colors.neutral70} />}
      nextIcon={<IconKeyboardArrowRight width={9} height={9} color={colors.neutral70} />}
      superPrevIcon={<IconFirstPage />}
      superNextIcon={<IconLastPage />}
      inputReadOnly
      placement="bottomLeft"
      format={'DD MMM YYYY'}
      locale={datePickerMapLocales(i18n.language)}
      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
    />
  );
};

export default DatePicker;
