import Header from './Sections/Header/Header';
import { useInView } from 'react-intersection-observer';

import TrialingInformation from './Sections/TrialingInformation';
import SeedDensityAdvice from 'pages/RecommendationV2/Sections/SeedDensityAdvice';

import IconUpArrow from 'components/Icons/IconUpArrow';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ProductPerformance from './Sections/ProductPerformance';
import { useAppState } from 'context/AppState';
import { useFlowActions } from 'context/actions/flowActions';
import { useApiDataActions } from 'context/actions/ApiDataActions';
import { Field, RecommendationCropwiseResponse, Tpr } from 'base/types/RecommendationCropwise';
import {
  BackToTopBtn,
  FeedbackBtn,
  FeedbackImg,
  LandscapeWarningMessageContainer,
  Root,
  ContentContainer,
  DiagnosticTab,
  RecommendationTab,
  PrimaryContainer,
  SecondaryContainer,
} from './RecommendationV2.styles';
import 'c3/c3.css';
import routes from 'base/constants/routes';
import lookup from 'country-code-lookup';
import ProxyLayer from 'base/api/ProxyLayer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useBreakpoint } from 'hooks';
import {
  ERecommendationSections,
  InputAndDiagnosticsTabName,
  RecommendationTabName,
} from 'utils/constants/RecommendationSections';
import RecommendationInfo from './Sections/RecommendationInfo/RecommendationInfo';
import { useTranslation } from 'react-i18next';
import feedbackIcon from '../../assets/images/feedback.png';
import track from 'utils/amplitudeWrapper';
import { UsageGroups } from 'utils/constants/UsageGroups';
import axios from 'axios';
import { UserLocation } from 'base/types/UserLocation';
import { useRecaptchaVerification } from 'hooks/useRecaptchaVerification';
import { showCookiebotIcon } from 'utils/helpers/cookiebot';
import { findRegionalLocation } from 'base/services/location';
import VoucherExplanation from './Sections/VoucherExplanation';
import { setUserId } from '@amplitude/analytics-browser';

export type PinInfoType = {
  longitude: number;
  latitude: number;
  location: string;
};
import ProductAdvice from './ProductAdvice';
import SectionTabs, { SectionTab } from './Sections/SectionTabs/SectionTabs';
import AgronomicPreferences from 'pages/RecommendationV2/Sections/AgronomicPreferences/AgronomicPreferences';
import { isBase64 } from 'utils/helpers/converter';
import EnvironmentalStress from 'pages/RecommendationV2/Sections/EnvironmentalStress';
import { Footer } from './Sections/Footer';
import { ESectionByDevice, useSectionByDevice } from './hooks/useSectionByDevice';
import SoilCharacteristics from './Sections/SoilCharacteristics';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { ErrorsTypeMap } from 'utils/constants/ErrorsType';
import { CropConstants, growthStageCrops } from 'utils/constants/Crop';
import { isUkraine } from 'utils/countryCode';
import GrowthStages from './Sections/GrowthStages/GrowthStages';
import { Divider } from 'syngenta-digital-cropwise-react-ui-kit';
import { findCentroidOfAllFieldsInRec } from 'utils/getCentroidFields';
import { StressType } from 'context/store/flowReducer';
import { trackGTMEvent } from 'utils/createGTMEvent';
import { createGrowthStageReqData } from 'utils/growthStage';

declare global {
  interface Window {
    pendo: any;
    pendoscript: any;
  }
}

export default function RecommendationV2() {
  const {
    flow: {
      appLanguage,
      abioticStageStressType,
      selectedAbioticFrequencyValue,
      selectedAbioticIntensityValue,
      localCountry,
    },
  } = useAppState();
  const rootRef = useRef<any>();
  const feedbackBtnRef = useRef<any>();
  const isPageScrolled = useRef<boolean>(false);
  const [showBackToTopBtn, setShowBackToTopBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPendoGuideActive, setIsPendoGuideActive] = useState(false);
  const pageLoadStartTimeRef = useRef(Date.now());
  const [isLoadingBreakoutsAPI, setIsLoadingBreakoutsAPI] = useState(true);
  const [isLoadingBreakoutsProductSummaryAPI, setIsLoadingBreakoutsProductSummaryAPI] =
    useState(true);
  const [isLoadingAttributesAPI, setIsLoadingAttributesAPI] = useState(true);
  const [environmentalStressChartInfo, setEnvironmentalStressChartInfo] = useState<Tpr | null>(
    null
  );
  const [propertiesFieldsInfo, setPropertiesFieldsInfo] = useState<Field[] | null>(null);
  const [pendoManualOpen, setPendoManualOpen] = useState(false);
  const [userSpentTwoMin, setUserSpentTwoMin] = useState(false);
  const [userSpentThirtySec, setUserSpentThirtySec] = useState(false);
  const [isPendoClosed, setIsPendoClosed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [growthStagePlantingDate, setGrowthStagePlantingDate] = useState<string>('');

  const { setEnvironmentalStressTableChartProps, setLocalCountry } = useFlowActions();
  const { isMobile, landscape } = useBreakpoint();
  const { isHumanUser, verifyRecaptcha } = useRecaptchaVerification();
  const isAnyAPILoading =
    isLoading ||
    isLoadingBreakoutsAPI ||
    isLoadingAttributesAPI ||
    isLoadingBreakoutsProductSummaryAPI;

  const option = { threshold: 0.2 };
  const { ref: TrialInformationRef, inView: TrialInformationSectionVisible } = useInView(option);
  const { ref: soilCharRef, inView: soilCharSectionVisible } = useInView(option);

  const { ref: FooterRef, inView: FooterSectionVisible } = useInView(option);
  const { ref: SeedDensityRef, inView: SeedDensitySectionVisible } = useInView(option);
  const { ref: ProductPerformRef, inView: ProductPerformSectionVisible } = useInView(option);
  const { ref: agPreferencesRef, inView: agPreferencesSectionVisible } = useInView(option);
  const { ref: ProductComparisonRef, inView: ProductComparisonSectionVisible } = useInView(option);
  const { ref: ExpertSeedRef, inView: ExpertSeedSectionVisible } = useInView(option);
  const { ref: EnvStressRef, inView: EnvStressSectionVisible } = useInView(option);
  const navigate = useNavigate();
  const params = useParams();
  let recId = '';
  let decodedRecParamString = '';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { recommendation_data = '' } = params;
  if (isBase64(recommendation_data)) {
    decodedRecParamString = atob(recommendation_data);
  } else {
    recId = recommendation_data;
  }
  const flags = useFlags();

  const ApiDataActions = useApiDataActions();
  const [selectedTab, setSelectedTab] = useState<SectionTab>(RecommendationTabName);
  const [isRecommendationAPILoading, setIsRecommendationAPILoading] = useState(true);
  const [currentSection, setCurrentSection] = useState(ERecommendationSections.SECTION_HERO_BANNER);
  const { currentSectionByDevice } = useSectionByDevice({ isMobile, landscape, currentSection });
  const sectionLandscapeAllowed = currentSectionByDevice === ESectionByDevice.landscapeAllowed;
  const [t] = useTranslation();
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const mapCharRef = useRef<HTMLDivElement>(null);
  const handleTabClick = (tab: SectionTab) => {
    setSelectedTab(tab);
    if (tab === InputAndDiagnosticsTabName) {
      track('diagnostics tab', { 'diagnostics tab clicked': true });
    } else if (tab === RecommendationTabName && showGrowthStage()) {
      fetchGSDataForPlantingDate(getStartDate());
      setGrowthStagePlantingDate(
        recommendationCropwise?.recommendations[0]?.multi_field?.config?.agronomicInputs
          ?.plantingDate ?? `${new Date().getFullYear()}-04-01`
      );
    }
    document
      .getElementById('recommendationProductsCard')
      ?.scrollIntoView({ behavior: 'auto', block: 'start' });
  };
  const handleProductSelection = (productName: string) => {
    setSelectedProduct(productName);
    mapCharRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const { i18n } = useTranslation();
  const {
    apiData: {
      recommendation,
      recommendationCropwise,
      productCatalog,
      nonDDGUserDetails,
      recommendationCropwiseError,
      breakoutsGlobalError,
      breakoutsProductsSummaryError,
      growthStage,
      abioticStage,
    },
  } = useAppState();
  const countryName = recommendationCropwise?.country ?? '';
  const isBrazil = countryName === CropConstants.Brazil;
  const isSouthAfrica = countryName === CropConstants.SOUTH_AFRICA;
  const countryCode = countryName ? lookup.byCountry(countryName?.trim())?.iso2 : '';
  const cropName = recommendationCropwise?.recommendations[0].crop_name ?? '';
  const isDDG = recommendationCropwise?.is_ddg;

  const showPreBookSeedsBtn = () => {
    return isDDG;
  };

  const location = useLocation();

  useLayoutEffect(() => {
    showCookiebotIcon();
  }, []);
  useEffect(() => {
    if (isHumanUser !== undefined) {
      return;
    }
    verifyRecaptcha();
  }, [isHumanUser, verifyRecaptcha]);

  useEffect(() => {
    if (!isMobile || currentSectionByDevice !== ESectionByDevice.desktopOrMobile) {
      // Not update the current visible section on landscape
      return;
    }

    const pageSections = {
      [ERecommendationSections.SECTION_TRIALING_INFORMATION]: TrialInformationSectionVisible,
      [ERecommendationSections.SECTION_SOILS_CHARACTERISTICS]: soilCharSectionVisible,
      [ERecommendationSections.SECTION_SEED_DENSITY_ADVICE]: SeedDensitySectionVisible,
      [ERecommendationSections.SECTION_PRODUCT_PERFORMANCE]: ProductPerformSectionVisible,
      [ERecommendationSections.SECTION_AG_PREFERENCES]: agPreferencesSectionVisible,
      [ERecommendationSections.SECTION_PRODUCT_COMPARISON]: ProductComparisonSectionVisible,
      [ERecommendationSections.SECTION_EXPERT_SEED_ADVICE]: ExpertSeedSectionVisible,
      [ERecommendationSections.SECTION_ENV_STRESS]: EnvStressSectionVisible,
    };

    const currentSectionAllowed = Object.keys(pageSections).find((key) => pageSections[key]);
    setCurrentSection(currentSectionAllowed || '');
  }, [
    TrialInformationSectionVisible,
    ProductPerformSectionVisible,
    currentSectionByDevice,
    SeedDensitySectionVisible,
    agPreferencesSectionVisible,
    ProductComparisonSectionVisible,
    ExpertSeedSectionVisible,
    isMobile,
    EnvStressSectionVisible,
    soilCharSectionVisible,
  ]);

  useEffect(() => {
    const dateAndTime = new Date().toUTCString();
    let sectionName = '';

    switch (true) {
      case TrialInformationSectionVisible:
        sectionName = 'trial information section';
        break;
      case SeedDensitySectionVisible:
        sectionName = 'seed density advices section';
        break;
      case ProductPerformSectionVisible:
        sectionName = 'product performance by stress level section';
        break;
      case agPreferencesSectionVisible:
        sectionName = 'agronomic preference section';
        break;
      case EnvStressSectionVisible:
        sectionName = 'environmental stress section';
        break;
      case ExpertSeedSectionVisible:
        sectionName = 'expert seed advice section';
        break;
      case ProductComparisonSectionVisible:
        sectionName = 'product comparison section';
        break;
      default:
        break;
    }

    if (sectionName) {
      track('recommendation scroll level', { [sectionName]: dateAndTime });
    }
  }, [
    TrialInformationSectionVisible,
    SeedDensitySectionVisible,
    ProductPerformSectionVisible,
    EnvStressSectionVisible,
    agPreferencesSectionVisible,
    ExpertSeedSectionVisible,
    ProductComparisonSectionVisible,
  ]);

  useEffect(() => {
    const cancelToken = ProxyLayer.cancelToken();
    (async () => {
      try {
        if (decodedRecParamString) {
          const input = {
            decodedRecParamString: decodedRecParamString,
            source: 'NonDDG',
          };
          ApiDataActions.getRecommendationCropwise(input, { cancelToken: cancelToken.token });
        } else {
          const input = {
            solutionId: recId,
            source: 'DDG',
          };
          await Promise.all([
            await ApiDataActions.getRecommendationInput(recId, { cancelToken: cancelToken.token }),
            await ApiDataActions.getRecommendationCropwise(input, {
              cancelToken: cancelToken.token,
            }),
          ]);
        }
        setIsRecommendationAPILoading(false);
      } catch (e) {
        // TODO: catch the error and display UI for the error
        console.error('Error!!', e);
      }
    })();

    return () => {
      cancelToken?.cancel();
    };
  }, [ApiDataActions, recId]);

  useEffect(() => {
    window.sessionStorage.setItem('langFromRecommendationPage', i18n.language);
  }, [i18n.language]);

  const trackPageOpen = () => {
    if (isDDG) {
      if (location?.search?.includes('dest=SMS')) {
        track('DDG rec opens', { 'opened through': 'SMS' });
      } else {
        track('DDG rec opens', { 'opened through': 'E-mail' });
      }
    }
  };

  const createAbioticStageReqData = (
    recommendationCropwise: RecommendationCropwiseResponse,
    plantingDate: string
  ) => {
    const { recommendations } = recommendationCropwise;
    const {
      crop_name: cropName,
      multi_field: {
        geometry: { properties },
      },
    } = recommendations[0];
    const fieldCenter = findCentroidOfAllFieldsInRec(properties[0].fields);
    const getPredictionStartDate = (date: string) => {
      const day = date.split('-')[2];
      const month = date.split('-')[1];
      const year = new Date().getFullYear();
      const startDate = `${
        abioticStageStressType === StressType.Frequency ? year : selectedAbioticIntensityValue
      }-${month}-${day}T00:00:00Z`;
      return startDate;
    };
    const getPredictionEndDate = () => {
      const date = endDateForGrowthStage();
      const day = Number(date.getDate().toString().padStart(2, '0'));
      const month = Number(date.getMonth().toString().padStart(2, '0'));
      const year = new Date().getFullYear();
      const endDate = `${
        abioticStageStressType === StressType.Frequency ? year : selectedAbioticIntensityValue
      }-${month + 1}-${day}T00:00:00Z`;
      return endDate;
    };
    const data = {
      request_version: 'v1.0',
      fields: [
        {
          models: [
            {
              name: 'abiotic_stress',
              version: 'v1.0',
              ...(abioticStageStressType === StressType.Frequency && {
                metadata: {
                  historical_time_period: {
                    no_years: selectedAbioticFrequencyValue === 'last20' ? 20 : 10,
                  },
                },
              }),
            },
          ],
          location: {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: fieldCenter?.geometry.coordinates,
            },
            properties: {
              distance_to_grid: {
                value: 50,
                unit: 'km',
              },
            },
          },
          query: {
            filter:
              abioticStageStressType === StressType.Frequency
                ? 'aggregated_stress_risk'
                : 'daily_stress_severity',
            prediction_time_period: {
              start: getPredictionStartDate(plantingDate), //'2024-04-30T00:00:00Z',
              end: getPredictionEndDate(), //'2024-10-31T00:00:00Z'
            },
          },
          crop: cropName.toUpperCase(),
        },
      ],
    };
    return data;
  };

  const fetchGSDataForPlantingDate = useCallback(
    (newDate: Date | string) => {
      if (flags.isGrowthStageEnabled && recommendationCropwise) {
        const date = typeof newDate === 'object' ? newDate.toISOString().split('T')[0] : newDate;
        const growthStageReqData = createGrowthStageReqData(
          recommendationCropwise,
          date,
          productCatalog
        );
        const cancelToken = ProxyLayer.cancelToken();
        if (growthStageReqData) {
          ApiDataActions.getGrowthStageDetails(growthStageReqData, cancelToken.token);
        }
      }
    },
    [recommendationCropwise, flags.isGrowthStageEnabled, ApiDataActions, productCatalog]
  );

  const handlePlantingDateOnGrowthGraph = useCallback(
    (newDate: Date) => {
      setGrowthStagePlantingDate(newDate.toISOString().split('T')[0]);
      fetchGSDataForPlantingDate(newDate);
    },
    [recommendationCropwise, flags.isGrowthStageEnabled, ApiDataActions, productCatalog]
  );

  useEffect(() => {
    if (recommendationCropwise && growthStagePlantingDate && showGrowthStage()) {
      getDataForAbioticStress(recommendationCropwise, growthStagePlantingDate);
    }
  }, [
    abioticStageStressType,
    selectedAbioticFrequencyValue,
    selectedAbioticIntensityValue,
    growthStagePlantingDate,
  ]);

  const getDataForAbioticStress = (
    recommendationCropwise: RecommendationCropwiseResponse,
    plantingDate: string
  ) => {
    const abioticStageReqData = createAbioticStageReqData(recommendationCropwise, plantingDate);
    const cancelToken = ProxyLayer.cancelToken();
    ApiDataActions.getAbioticStageDetails(abioticStageReqData, cancelToken.token);
  };

  const getStartDate = () => {
    return new Date(
      recommendationCropwise?.recommendations[0].multi_field.config?.agronomicInputs
        ?.plantingDate ?? `${new Date().getFullYear()}-04-01`
    );
  };

  const showGrowthStage = () => {
    if (recommendationCropwise) {
      return (
        flags?.isGrowthStageEnabled &&
        !moment(
          recommendationCropwise?.recommendations[0].multi_field.config?.agronomicInputs
            ?.plantingDate
        ).isBefore(moment('2024-01-22')) &&
        growthStageCrops?.includes(cropName)
      );
    }
    return false;
  };

  useEffect(() => {
    const getPinDataForEnvironmentalStressTable = () => {
      if (
        recommendationCropwise?.solution_type &&
        recommendationCropwise?.solution_type === 'spot_check'
      ) {
        const pinCoordinates =
          recommendationCropwise?.recommendations[0]?.multi_field?.geometry?.properties[0]
            ?.reference_point?.coordinates;
        const pinData: PinInfoType = {
          longitude: Number(pinCoordinates[0].toFixed(3)),
          latitude: Number(pinCoordinates[1].toFixed(3)),
          location: recommendationCropwise?.country.trim(),
        };
        return {
          pinInfo: pinData,
          isDropAPin: true,
        };
      }
      return {
        pinInfo: null,
        isDropAPin: false,
      };
    };

    (async () => {
      try {
        if (recommendationCropwise) {
          if (recommendationCropwise.email_id) {
            const timeToGetRecommendation = moment
              .duration(moment.utc().diff(moment(recommendationCropwise.updated_on).utc()))
              .asMinutes();
            track('clicks on get recommendation', {
              'user clicks on get recommendation': timeToGetRecommendation,
            });
          }
          trackPageOpen();
          // Data for Pin
          const pinDataEnvironmentTable = getPinDataForEnvironmentalStressTable();

          // Data for Environmental Stress
          const { recommendations } = recommendationCropwise;
          const {
            crop_type: cropType,
            multi_field: {
              assignments,
              config: { recommendation: recommendationInfo },
              geometry: { properties },
            },
            crop_id: cropId,
          } = recommendations[0];
          const propertiesFieldInfo = properties.map((item) => item.fields).flat();
          let propertiesAndBoundaries;
          if (propertiesFieldInfo.length) {
            propertiesAndBoundaries = propertiesFieldInfo.map((propertyField: Field) => {
              return {
                ...propertyField,
                estimatedSize: propertyField.declared_area || 0, // Get the hectares from the propertyField
              };
            });
            setPropertiesFieldsInfo(propertiesAndBoundaries);
          }
          if (recommendationInfo.length) {
            const biggestField = assignments.reduce(
              (prevValue, currentAssignment) => {
                const size =
                  currentAssignment?.products?.length > 0
                    ? currentAssignment.products[0].applied_area
                    : 0;
                if (size > prevValue.size) {
                  prevValue = {
                    id: currentAssignment.field_id,
                    size: size,
                  };
                }
                return prevValue;
              },
              { id: '', size: 0 }
            );
            const biggestRecommendationInfo =
              recommendationInfo.find((recomm) => recomm.FieldId === biggestField.id) ||
              recommendationInfo[0];
            const info = biggestRecommendationInfo?.AdditionalData.YieldRankEurope.tpr;
            setEnvironmentalStressChartInfo(info || null);
          }

          setEnvironmentalStressTableChartProps({
            props: {
              ...pinDataEnvironmentTable,
              propertiesFieldsInfo: propertiesAndBoundaries ?? null,
              tabInfo: recommendationInfo.length ? recommendationInfo : null,
            },
          });

          const countryName = recommendationCropwise?.country || '';
          let countryIsoCode = '';
          const countryCode = recommendationCropwise?.country
            ? lookup.byCountry(recommendationCropwise?.country)?.iso3
            : '';
          if (decodedRecParamString) {
            //call userdetails api and bind the data as required
            const urlParams = new URLSearchParams(decodedRecParamString);
            const email = urlParams.get('email') ?? '';
            const cancelToken = ProxyLayer.cancelToken();
            Promise.all([ApiDataActions.getUserDetails(email, cancelToken.token)]);
          }
          countryIsoCode = lookup.byCountry(countryName)?.iso2 ?? '';
          Promise.all([
            ApiDataActions.getProductCatalog(cropType, countryName),
            ApiDataActions.getCountries(false, countryIsoCode),
          ]).then(() => setIsLoading(false));
          Promise.all([
            ApiDataActions.getBreakoutsGlobalRecommendationId({
              solutionId: recommendationCropwise.id,
              ...(decodedRecParamString && { decodedRecParamString: decodedRecParamString }),
              source: decodedRecParamString ? 'NonDDG' : 'DDG',
              withFilters: false,
            }),
            ApiDataActions.getBreakoutsGlobalRecommendationId({
              solutionId: recommendationCropwise.id,
              source: decodedRecParamString ? 'NonDDG' : 'DDG',
              ...(decodedRecParamString && { decodedRecParamString: decodedRecParamString }),
              withFilters: true,
            }),
          ]).then(() => setIsLoadingBreakoutsAPI(false));

          Promise.all([
            ApiDataActions.getBreakoutsProductsSummaryRecommendationId({
              solutionId: recommendationCropwise.id,
              ...(decodedRecParamString && { decodedRecParamString: decodedRecParamString }),
              source: decodedRecParamString ? 'NonDDG' : 'DDG',
              withFilters: false,
            }),
            ApiDataActions.getBreakoutsProductsSummaryRecommendationId({
              solutionId: recommendationCropwise.id,
              ...(decodedRecParamString && { decodedRecParamString: decodedRecParamString }),
              source: decodedRecParamString ? 'NonDDG' : 'DDG',
              withFilters: true,
            }),
          ])
            .then(() => setIsLoadingBreakoutsProductSummaryAPI(false))
            .catch(() => setIsLoadingBreakoutsProductSummaryAPI(false));
          ApiDataActions.getAttributes(undefined, countryCode, cropId, [
            UsageGroups.WEIGHTING,
            UsageGroups.PDF,
          ]).then(() => setIsLoadingAttributesAPI(false));
        }
      } catch (error) {
        // TODO: catch the error and display UI for the error
        console.error('Error!!', error);
      }
    })();
  }, [
    recommendationCropwise,
    // ApiDataActions,
    // recommendation,
    // recommendationCropwise?.country,
    // setEnvironmentalStressTableChartProps,
    // isHumanUser,
  ]);

  useEffect(() => {
    try {
      if (recommendationCropwise && growthStage.length === 0 && productCatalog.length > 0) {
        const { recommendations } = recommendationCropwise;
        const {
          multi_field: {
            config: { agronomicInputs },
          },
        } = recommendations[0];
        if (showGrowthStage()) {
          setGrowthStagePlantingDate(
            agronomicInputs.plantingDate ?? `${new Date().getFullYear()}-04-01`
          );
          fetchGSDataForPlantingDate(
            agronomicInputs.plantingDate ?? `${new Date().getFullYear()}-04-01`
          );
        }
      }
    } catch (error) {
      console.error('Error fetching growth stage data:', error);
    }
  }, [productCatalog]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const fetchRegionalData = async () => {
      try {
        const data: UserLocation = await findRegionalLocation({ cancelToken: cancelToken?.token });
        const { country = 'de' } = data || {};
        setLocalCountry({ country: String(country).toLocaleLowerCase() });
      } catch (e) {
        console.error('error', e);
      }
    };
    fetchRegionalData();
    return () => {
      cancelToken?.cancel();
    };
  }, []);

  const trackPageLoadCompletionTime = () => {
    const pageLoadStartTime = pageLoadStartTimeRef.current;
    const pageLoadEndTime = Date.now();
    const pageLoadTime = (pageLoadEndTime - pageLoadStartTime) / 1000;
    track('recommendation loading stats', {
      'load start time': new Date(pageLoadStartTime).toLocaleTimeString(),
      'load end time': new Date(pageLoadEndTime).toLocaleTimeString(),
      'load duration': pageLoadTime,
    });
  };

  useEffect(() => {
    if (!isLoading) {
      if (!decodedRecParamString) {
        ProxyLayer.sendMarketingCommercialEmail({ recommendationSolutionId: recId })
          .then((res) => {
            if (res?.data && res?.data.recommendation_solution_id) {
              console.log('Email sent successfully', res.data);
              trackGTMEvent('lead_form', countryCode, localCountry);
            }
          })
          .catch(() => {
            // TO-DO:
          });
      }
    }
    if (!isAnyAPILoading) {
      trackPageLoadCompletionTime();
    }
  }, [isLoading, isAnyAPILoading, recId]);

  useEffect(() => {
    if (recommendation || recommendationCropwise) {
      const language = localStorage.getItem('i18nextLng');
      const email = recommendationCropwise?.is_ddg
        ? recommendation?.userContact.email
        : nonDDGUserDetails.email;
      const userName = recommendation?.userContactFromAudit;
      if (window.pendo && email) {
        window.pendo.initialize({
          visitor: {
            id: email,
            email: email,
            full_name: recommendationCropwise?.is_ddg
              ? `${userName?.firstName} ${userName?.lastName}`
              : nonDDGUserDetails.name,
            local_langauge: language,
          },
          account: {
            id: 'DDG-PENDO-ANALYSIS-ACCOUNT',
          },
        });
      }
      if (email) {
        setUserId(email);
      }
    }
  }, [recommendation, recommendationCropwise, nonDDGUserDetails]);

  useEffect(() => {
    const handleTabClose = () => {
      const pageLoadStartTime = pageLoadStartTimeRef.current;
      const tabCloseTime = Date.now();
      const timeSpentDuration = (tabCloseTime - pageLoadStartTime) / 1000;
      track('time spent on recommendation page', {
        'time user spends on recommendation page': timeSpentDuration,
      });
    };

    window.addEventListener('beforeunload', handleTabClose);
    return () => window.removeEventListener('beforeunload', handleTabClose);
  }, []);

  useEffect(() => {
    if (window.pendo && appLanguage) {
      const language = appLanguage || localStorage.getItem('i18nextLng');
      window.pendo.identify({
        visitor: {
          local_langauge: language,
        },
      });
    }
  }, [appLanguage]);

  useEffect(() => {
    if (
      (TrialInformationSectionVisible || FooterSectionVisible) &&
      userSpentTwoMin &&
      userSpentThirtySec &&
      !pendoManualOpen
    ) {
      feedbackBtnRef?.current?.click();
    }
  }, [
    TrialInformationSectionVisible,
    FooterSectionVisible,
    userSpentTwoMin,
    pendoManualOpen,
    userSpentThirtySec,
  ]);

  useEffect(() => {
    if (!isLoading && recommendation) {
      rootRef?.current?.addEventListener('scroll', () => {
        if (!isPageScrolled.current && rootRef.current.scrollTop > 100) {
          isPageScrolled.current = true;
          setTimeout(() => {
            setUserSpentTwoMin(true);
          }, 2 * 60 * 1000);
        }
      });
    }
  }, [isLoading, recommendation]);

  useEffect(() => {
    if (FooterSectionVisible && !isPendoClosed) {
      setTimeout(() => {
        setUserSpentThirtySec(true);
      }, 30 * 1000);
    }
  }, [FooterSectionVisible, isPendoClosed]);

  useEffect(() => {
    document.getElementById('pendo-close-guide-b37702a5')?.addEventListener('click', () => {
      setIsPendoGuideActive(false);
      setIsPendoClosed(true);
    });
  }, [isPendoGuideActive]);

  useLayoutEffect(() => {
    const element = rootRef.current;

    function checkSrollPos() {
      if (rootRef.current.scrollTop > 100) {
        setShowBackToTopBtn(true);
      } else if (rootRef.current.scrollTop < 100) {
        setShowBackToTopBtn(false);
      }
    }
    element.addEventListener('scroll', checkSrollPos);

    return () => {
      element.removeEventListener('scroll', checkSrollPos);
    };
  }, []);

  const backToTop = () => {
    rootRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const feedbackClick = () => {
    setPendoManualOpen(true);
    setIsPendoGuideActive(true);
  };

  const handleLogoAction = () => {
    if (isDDG) {
      navigate(routes.home);
    }
  };
  useEffect(() => {
    if (recommendationCropwiseError || breakoutsGlobalError || breakoutsProductsSummaryError) {
      setShowModal(true);
    }
  }, [recommendationCropwiseError, breakoutsGlobalError, breakoutsProductsSummaryError]);

  const endDateForGrowthStage = () => {
    const agronomicInputs =
      recommendationCropwise?.recommendations[0].multi_field.config?.agronomicInputs;
    if (agronomicInputs?.harvestDate) {
      return new Date(agronomicInputs.harvestDate);
    } else {
      const plantingDate = agronomicInputs?.plantingDate;
      return new Date(
        `${plantingDate ? plantingDate.split('-')[0] : new Date().getFullYear()}-10-31`
      );
    }
  };

  return (
    <Root ref={rootRef}>
      {currentSectionByDevice !== ESectionByDevice.desktopOrMobile && (
        <LandscapeWarningMessageContainer>
          {t('Landscape for cellphone message')}
        </LandscapeWarningMessageContainer>
      )}
      <Header logoAction={handleLogoAction} countryCode={countryCode} />
      <ContentContainer cropName={cropName ?? ''}>
        <RecommendationInfo
          isRecommendationAPILoading={isRecommendationAPILoading}
          isNonDDG={!!decodedRecParamString}
        />
        <SoilCharacteristics
          mapCharRef={mapCharRef}
          isLoading={isLoading}
          sectionRef={soilCharRef}
          isMobile={isMobile}
          landscapeAllowed={
            sectionLandscapeAllowed &&
            currentSection === ERecommendationSections.SECTION_SOILS_CHARACTERISTICS
          }
          selectProduct={selectedProduct}
          setSelectedProductAsEmpty={() => setSelectedProduct('')}
          isDDG={isDDG}
        />
        <SectionTabs
          selectedTab={selectedTab}
          handleTabClick={handleTabClick}
          isDDG={isDDG}
          countryCode={countryCode}
        />
        {selectedTab === RecommendationTabName ? (
          <RecommendationTab>
            <PrimaryContainer>
              <ProductAdvice
                isMobile={isMobile}
                isLoadingBreakoutsAPI={isLoadingBreakoutsAPI}
                isLoadingBreakoutsProductSummaryAPI={isLoadingBreakoutsProductSummaryAPI}
                isLoadingAttributesAPI={isLoadingAttributesAPI}
                ProductComparisonRef={ProductComparisonRef}
                ExpertSeedRef={ExpertSeedRef}
                onClickToSeeDetails={() => handleTabClick(InputAndDiagnosticsTabName)}
                handleProductSelection={handleProductSelection}
              />
              {!isSouthAfrica && (
                <ProductPerformance
                  sectionRef={ProductPerformRef}
                  landscapeAllowed={
                    sectionLandscapeAllowed &&
                    currentSection === ERecommendationSections.SECTION_PRODUCT_PERFORMANCE
                  }
                />
              )}
              <TrialingInformation
                isLoading={isLoadingBreakoutsAPI || isLoadingBreakoutsProductSummaryAPI}
                isHumanUser={isHumanUser}
                sectionRef={TrialInformationRef}
              />
            </PrimaryContainer>
            <SecondaryContainer>
              {showGrowthStage() && (
                <>
                  <GrowthStages
                    products={growthStage}
                    cropName={cropName}
                    startDate={getStartDate()}
                    endDate={endDateForGrowthStage()}
                    onPlantingDateChange={handlePlantingDateOnGrowthGraph}
                    stressLevel={abioticStage}
                    productList={recommendationCropwise?.recommendations[0].products}
                  />
                  <Divider style={{ margin: isMobile ? '' : '0 -64px', width: 'auto' }} />
                </>
              )}
              {!isLoading && (
                <SeedDensityAdvice
                  sectionRef={SeedDensityRef}
                  recommendationCropwise={
                    recommendationCropwise?.recommendations[0].multi_field.config || {}
                  }
                  recommendationAssignments={
                    recommendationCropwise?.recommendations[0].multi_field.assignments || {}
                  }
                  propertiesFieldsInfo={propertiesFieldsInfo || []}
                  productsInfo={recommendationCropwise?.recommendations[0].products || []}
                  productsCatalogInfo={productCatalog || []}
                  landscapeAllowed={
                    sectionLandscapeAllowed &&
                    currentSection === ERecommendationSections.SECTION_SEED_DENSITY_ADVICE
                  }
                  countryCode={
                    recommendationCropwise?.country.trim() === 'Brazil'
                      ? lookup.byCountry(recommendationCropwise?.country)?.iso2
                      : recommendationCropwise?.country &&
                        lookup.byCountry(recommendationCropwise?.country)?.iso3
                  }
                  cropId={recommendationCropwise?.recommendations[0]?.crop_id}
                  isNonDDG={!!decodedRecParamString}
                />
              )}
            </SecondaryContainer>
          </RecommendationTab>
        ) : (
          <DiagnosticTab>
            <p className="heading">{t('How we Arrived at Our Recommendation')}</p>
            <AgronomicPreferences sectionRef={agPreferencesRef} />
            {!isBrazil && !isSouthAfrica && (
              <EnvironmentalStress
                sectionRef={EnvStressRef}
                chartInfo={environmentalStressChartInfo}
              />
            )}
          </DiagnosticTab>
        )}
        {showPreBookSeedsBtn() && !isUkraine(countryCode) && (
          <VoucherExplanation
            isMobile={isMobile}
            countryCode={countryCode}
            isLoading={false}
            cropId={recommendationCropwise?.recommendations[0].crop_id ?? recommendation?.cropId}
            pageLoadStartTime={pageLoadStartTimeRef.current}
          />
        )}
        <Footer sectionRef={FooterRef} />
        <BackToTopBtn onClick={backToTop} visible={showBackToTopBtn}>
          <IconUpArrow color="white" />
        </BackToTopBtn>
        {window.pendo && (
          <FeedbackBtn className="pendo-feedback" ref={feedbackBtnRef} onClick={feedbackClick}>
            <FeedbackImg src={feedbackIcon} alt="feedback" />
          </FeedbackBtn>
        )}
      </ContentContainer>
      {showModal &&
        (recommendationCropwiseError || breakoutsGlobalError || breakoutsProductsSummaryError) && (
        <ConfirmationModal
          data-testid="error-modal"
          title={t(ErrorsTypeMap.MAX_ATTEMPTS.statusTitle ?? '')}
          body={t(ErrorsTypeMap.MAX_ATTEMPTS.statusMessage)}
          confirmButtonText={t('OK')}
          onClickConfirm={() => setShowModal(false)}
          showPopUpforMobile={true}
        />
      )}
    </Root>
  );
}
