/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-bracket-newline */
import DrawAndDropMap from './DrawAndDropMap';
import { useFlowActions } from 'context/actions/flowActions';
import { useAppState, useAppDispatch } from 'context/AppState';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecommendationFormActions } from 'context/actions/recommendationFormActions';
import { useMap } from 'react-map-gl';
import debounce from 'utils/debounce';
import {
  pointInCountry,
  calculateAreaOfPolygon,
  pointIsInSpecificCountry,
} from 'utils/helpers/geospatial';
import { FlowSteps, ModeTypes, OptionType } from 'context/store/flowReducer';
import { ButtonBaseProps } from 'components/Buttons/ButtonBase';
import ProxyLayer from 'base/api/ProxyLayer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MapContainer } from 'components/Maps/Maps.styles';
import useValidationSteps from '../../pages/BoundaryWizard/hooks/useValidationSteps';
import { useApiDataActions } from 'context/actions/ApiDataActions';
import { detectFieldFeature } from 'context/store/recommendationFormReducer';
import { CropConstants } from 'utils/constants/Crop';
import track from 'utils/amplitudeWrapper';
import { notification } from 'antd';
import { trackGTMEvent } from 'utils/createGTMEvent';

export default function MultiFunctionMap({ id }: Readonly<{ id: string }>): JSX.Element {
  const mapContainerRef = useRef(null);
  const {
    flow: {
      isContinueButtonActive,
      optionType,
      currentStep,
      isLocationEnabled,
      currentModeType,
      isDrawingBoundaries,
      resetBoundaryValues,
      isAnotherRecommendationCreated,
      isDropAPinFlow,
      fieldToBeSaved,
      hasDetectFieldFlowStarted,
      fieldsMapData,
      tempFieldMapData,
      localCountry
    },
    recommendationForm,
    map: { center: mapCenter, mapStyle, userDropPinPosition },
    apiData: { fieldSoils, formattedDetectFieldsData },
  } = useAppState();
  const flowActions = useFlowActions();
  const {
    setShowLandIsNotArableModal,
    setShowPinWarning,
    setIsLocationEnabled,
    setIsDropAPinFlow,
    setOptionType,
    setResetBoundaryValues,
    setShowCancelBoundaryModal,
    setCurrentModeType,
    setFieldsMapData,
    setTempFieldMapData,
    setShowDrawingWarning
  } = flowActions;
  const recommendationFormActions = useRecommendationFormActions();
  const {
    pin,
    countryCode,
    isAtleastAVertex,
    agronomicInputs,
    tempBoundaryField,
    selectedCrop,
    fieldIdToEdit,
    tempFields,
    fields,
  } = recommendationForm;

  const appDispatcher = useAppDispatch();
  const { t } = useTranslation();
  const flags = useFlags();
  const [dropDisabledNextBtn, setDropDisabledNextBtn] = useState(false);
  const [movingBack, setMovingBack] = useState(false);

  const { flowMap, flowMapMobile } = useMap();
  const map = flowMap ?? flowMapMobile;
  const [dropDisabledPrevBtn, setDropDisabledPrevBtn] = useState(true);

  const [longitude, latitude] = mapCenter;
  const ApiDataActions = useApiDataActions();

  const {
    plantingDate,
    agProducts,
    yieldRangeId,
    rotationIntensity,
    selectedHerbicideTrait,
    tillagePractice,
    selectedSegment,
    selectedBroomrape,
    isBroomrapeInfestation,
  } = agronomicInputs;

  const { nextButtonText, backButtonText } = useValidationSteps({
    currentStep,
    currentModeType,
    agProducts,
    plantingDate,
    selectedCrop,
    yieldRangeId,
    rotationIntensity,
    tillagePractice,
    selectedHerbicideTrait,
    selectedSegment,
    isBroomrapeInfestation,
    selectedBroomrape,
    isContinueButtonActive,
    isAnotherRecommendationCreated,
  });

  const getCoordinates = () => {
    let longitudeCord: number;
    let latitudeCord: number;
    if (isDropAPinFlow) {
      const { lng, lat } = userDropPinPosition;
      longitudeCord = lng;
      latitudeCord = lat;
    } else {
      const latLng = tempBoundaryField?.properties?.center;
      const [long, lat] = latLng;
      longitudeCord = long;
      latitudeCord = lat;
    }
    return {
      longitudeCord,
      latitudeCord,
    };
  };

  const getFieldName = () => `${t('Field')} ${String(fields.length + 1).padStart(2, '0')}`;

  const getFieldSize = (tempBoundaryFieldMobile: any) => {
    const { boundary, geometry } = tempBoundaryFieldMobile;
    const coords = boundary ? boundary.geometry.coordinates : geometry.coordinates;
    let tempFieldArea = calculateAreaOfPolygon(coords[0]);
    tempFieldArea = parseFloat(tempFieldArea).toFixed(2).toString();
    return tempFieldArea;
  };

  const handleFieldSave = () => {
    if (tempBoundaryField) {
      const unsavedFieldData = {
        id: tempBoundaryField.properties?.id,
        fieldName: getFieldName(),
        hectares: getFieldSize(tempBoundaryField) || '10',
        boundary: tempBoundaryField,
        soils: tempBoundaryField.soils,
        weather: tempBoundaryField.weather,
        selectedSoils: [],
        fieldsResolution: fieldSoils.fieldsResolution,
        isSaved: true,
      };
      recommendationFormActions.setFieldToBeOpenedId({ fieldId: unsavedFieldData.id });
      recommendationFormActions.saveFields({ fields: [...fields, unsavedFieldData] });
      setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
      recommendationFormActions.setTempBoundaryField({ boundary: undefined });
      setCurrentModeType({ modeType: ModeTypes.EDITING });
      const fieldMapObject = {
        geometry: tempBoundaryField.geometry,
        type: 'Feature',
        id: String(unsavedFieldData.id),
        properties: {
          id: unsavedFieldData.id,
          fieldUniqueId: unsavedFieldData.id.toString(),
          hectares: unsavedFieldData.hectares,
        },
      };

      const mapData = {
        type: 'FeatureCollection',
        features: [...fieldsMapData.features, fieldMapObject],
        isDigifarm: formattedDetectFieldsData.isDigifarm,
      };
      setFieldsMapData({ data: mapData });
      flowActions.setShowFieldInformationSheet({ showFieldInformationSheet: true });
    }
  };

  const handleIsAreableLand = async (code = '') => {
    const { longitudeCord, latitudeCord } = getCoordinates();
    if (pointIsInSpecificCountry({ lng: longitudeCord ?? 0, lat: latitudeCord ?? 0 }, CropConstants.SOUTH_AFRICA_CODE)) {
      const tppData = await ApiDataActions.getTPPForFields(CropConstants.SOUTH_AFRICA, CropConstants.WHEAT_SMALLCASE, [latitudeCord, longitudeCord]);
      if (!Object.keys(tppData)?.includes('spatialClass')) {
        isDropAPinFlow
          ? setShowPinWarning({ show: true })
          : setShowDrawingWarning({ show: true });
        return;
      }
    }

    if (isDropAPinFlow) {
      recommendationFormActions.setPinPosition({ lngLat: userDropPinPosition });
      console.log('countryCode MultiFunctionMap', code);
      track('confirm pin location', { 'pin created': true });
      recommendationFormActions.setCountryCode({ countryCode: code ?? '' });
      flowActions.setShowFieldInformationSheet({ showFieldInformationSheet: true });
    } else {
      track('confirm boundary created', { 'boundary created': true });
      handleFieldSave();
    }
  };

  const checkIsArebleLand = (code = '') => {
    if (map) {
      const checkIsLandArable = async (): Promise<{ arable_land: boolean }> => {
        const cancelToken = ProxyLayer.cancelToken();
        let response = {
          arable_land: true,
        };
        try {
          const { longitudeCord, latitudeCord } = getCoordinates();
          response = await ApiDataActions.isLandArable(
            longitudeCord,
            latitudeCord,
            cancelToken.token,
            flags.isCheckLandArableEnabled
          );
        } catch (e) {
          console.log('Error!!', e);
        } finally {
          cancelToken?.cancel();
        }
        return response;
      };

      checkIsLandArable().then(async (res) => {
        if (res?.arable_land) {
          handleIsAreableLand(code);
        } else {
          setShowLandIsNotArableModal({ show: true });
        }
      });
    }
  };

  useEffect(() => {
    notification.destroy();
  }, []);

  useEffect(() => {
    checkIsArebleLand();
  }, [fieldToBeSaved]);

  useEffect(() => {
    // enable/disable next btn

    switch (currentStep) {
      case FlowSteps.STEP1:
        setDropDisabledNextBtn(true);
        setDropDisabledPrevBtn(false);
        break;
      case FlowSteps.STEP2:
        setDropDisabledNextBtn(false);
        setDropDisabledPrevBtn(false);
        break;
      default:
        break;
    }
  }, [currentStep]);

  const handleEditSave = () => {
    const updatedTempField: any = {
      ...tempBoundaryField,
      properties: {
        ...tempBoundaryField?.properties,
        id: fieldIdToEdit,
      },
    };
    if (fieldIdToEdit) {
      const fieldMapObject: detectFieldFeature = {
        geometry: updatedTempField.geometry,
        type: 'Feature',
        id: fieldIdToEdit,
        properties: {
          id: fieldIdToEdit,
          fieldUniqueId: fieldIdToEdit?.toString(),
          hectares: updatedTempField.hectares,
        },
      };
      setFieldsMapData({
        data: {
          type: 'FeatureCollection',
          features: [...fieldsMapData.features, fieldMapObject],
          isDigifarm: formattedDetectFieldsData.isDigifarm,
        },
      });
    }
    setTempFieldMapData({ data: undefined });
    const updatedFieldData = tempFields.map((item) => {
      if (item.id === fieldIdToEdit) {
        const fieldSize = getFieldSize(updatedTempField);
        recommendationFormActions.setCurrentFieldAreaMobile({ currentFieldAreaMobile: fieldSize });
        return {
          ...item,
          boundary: updatedTempField,
          hectares: fieldSize,
        };
      }
      return item;
    });
    recommendationFormActions.saveFields({ fields: updatedFieldData });
    recommendationFormActions.setTempBoundaryField({ boundary: undefined });
    setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
    recommendationFormActions.setFieldIdToEdit({ fieldId: '' });
    flowActions.setShowFieldInformationSheet({ showFieldInformationSheet: true });
  };

  const handleNextAction = () => {
    if (currentStep === FlowSteps.STEP1) {
      trackGTMEvent('user_draw_field', countryCode, localCountry);
      if (isDrawingBoundaries && isAtleastAVertex) {
        flowActions.setSaveWithoutClosingBoundary({
          saveWithoutClosingBoundary: true,
        });
      } else if (currentModeType === ModeTypes.EDITING && tempBoundaryField) {
        handleEditSave();
      } else if (!tempBoundaryField && fields.length > 0) {
        setCurrentModeType({ modeType: ModeTypes.EDITING });
        flowActions.setShowFieldInformationSheet({ showFieldInformationSheet: true });
      } else {
        checkIsArebleLand();
      }
    }
  };

  const handleDrawBoundaryCancel = () => {
    if (hasDetectFieldFlowStarted) {
      if (isAtleastAVertex) {
        setShowCancelBoundaryModal({ show: true });
      } else {
        setOptionType({ type: OptionType.Detect });
        setCurrentModeType({ modeType: ModeTypes.NO_ACTION });
      }
      return;
    }
    if (fieldIdToEdit && currentModeType === ModeTypes.EDITING) {
      if (tempFieldMapData) {
        setFieldsMapData({
          data: {
            type: 'FeatureCollection',
            features: [...fieldsMapData.features, tempFieldMapData],
            isDigifarm: formattedDetectFieldsData.isDigifarm,
          },
        });
      }
      setResetBoundaryValues({ resetBoundaryValues: !resetBoundaryValues });
      recommendationFormActions.setFieldIdToEdit({ fieldId: '' });
      recommendationFormActions.setTempBoundaryField({ boundary: undefined });
      recommendationFormActions.saveFields({ fields: tempFields });
      return;
    }
    if (isAtleastAVertex || fields) {
      setShowCancelBoundaryModal({ show: true });
    } else {
      setOptionType({ type: OptionType.empty });
      appDispatcher({ type: 'reset-app' });
    }
  };

  const drawStepsControlProps = useMemo(() => {
    return {
      buttonPreviousProps: {
        'aria-label': backButtonText,
        onClick: () => handleDrawBoundaryCancel(),
      },
      buttonNextProps: {
        'aria-label': nextButtonText,
        onClick: () => handleNextAction(),
      },
    };
  }, [
    backButtonText,
    nextButtonText,
    isAtleastAVertex,
    setShowCancelBoundaryModal,
    setIsDropAPinFlow,
    handleNextAction,
  ]);

  const handleDropNextClick = () => {
    if (map) {
      const code = pointInCountry(userDropPinPosition, flags);
      if (!code) {
        setShowPinWarning({ show: true });
        return;
      }
      console.log('countryCode MultiFunctionMap', code);
      checkIsArebleLand(code);
    }
  };

  const dropStepsControlProps = useMemo(
    () => ({
      buttonPreviousProps: {
        'aria-label': t('Previous Step'),
        onClick: () => {
          setIsDropAPinFlow({ isDropAPin: false });
          setOptionType({ type: OptionType.empty });
          appDispatcher({ type: 'reset-app' });
        },
        disabled: dropDisabledPrevBtn,
        text: t('Cancel'),
      } as ButtonBaseProps,
      buttonNextProps: {
        'aria-label': t('Confirm'),
        onClick: () => handleDropNextClick(),
        disabled: dropDisabledNextBtn,
        text: t('Confirm'),
      } as ButtonBaseProps,
    }),
    [
      t,
      dropDisabledPrevBtn,
      dropDisabledNextBtn,
      flowActions,
      flags,
      map,
      recommendationFormActions,
      userDropPinPosition,
      countryCode,
    ]
  );

  useLayoutEffect(() => {
    const mapReference = map?.getMap();

    function moveEndEvent(event: any) {
      const { target: map } = event;
      if (movingBack === false) {
        recommendationFormActions.addItemToHistory(map.getCenter());
      } else {
        setMovingBack(false);
      }
    }

    mapReference?.on('load', () => {
      mapReference.setLayoutProperty('poi-label', 'visibility', 'none');
    });

    mapReference?.on?.('moveend', moveEndEvent);

    return () => {
      mapReference?.off?.('moveend', moveEndEvent);
    };
    // eslint-disable-next-line array-bracket-newline
  }, [pin.positionHistory, flowActions, movingBack, map, recommendationFormActions]);

  useLayoutEffect(() => {
    setIsLocationEnabled({ isEnabled: true });
    const mapReference = map?.getMap();
    const resizer = new ResizeObserver(
      debounce(() => {
        // Recenter the map on any container size change
        try {
          mapReference?.resize();
          if (pin.position && countryCode && isDropAPinFlow) {
            const { lat, lng } = pin.position;
            mapReference?.flyTo({
              center: [lng, lat],
              zoom: 15,
              essential: true,
              duration: 0,
            });
          }
        } catch (e) {
          console.error('MAP_ERROR', e);
        }
      })
    );

    if (mapContainerRef.current) {
      resizer.observe(mapContainerRef.current);
    }

    return () => {
      resizer.disconnect();
    };
  }, [map, pin.position, countryCode, id, optionType]);

  useLayoutEffect(() => {
    const mapReference = map;
    if (mapReference) {
      recommendationFormActions.addItemToHistory(mapReference.getMap().getCenter());
    }
  }, [recommendationFormActions, map]);

  return (
    <MapContainer
      ref={mapContainerRef}
      id="map-container"
      data-testid="map-container"
      className={`${optionType?.toLocaleLowerCase()}-flow`}
    >
      <DrawAndDropMap
        mapId={id}
        longitude={longitude}
        latitude={latitude}
        mapStyle={mapStyle}
        stepsControlProps={dropStepsControlProps}
        drawStepControlProps={drawStepsControlProps}
        isLocationEnabled={isLocationEnabled}
        showPin={true}
      />
    </MapContainer>
  );
}
