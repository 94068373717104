import { TermsConditionsPrivacyPolicy } from 'pages/RecommendationV2/Sections/Footer';
import { CropConstants } from './Crop';

const syngentaURL = 'https://www.syngenta';
const TermsConditionPrivacyPolicyUrls: TermsConditionsPrivacyPolicy[] = [
  {
    country: 'Hungary',
    countryCode: 'HU',
    countryId: 'HUN',
    termsConditionURL: `https://hu.cropwise.com/szerzodesi-feltetelek`,
    privacyPolicy: `https://hu.cropwise.com/adatvedelmi-tajekoztato`,
    cookiePolicyURL: `${syngentaURL}.hu/suti-cookie-szabalyzat`,
    priceDrawRulesPdf: `${syngentaURL}.hu/sites/g/files/kgtney1461/files/media/document/2024/10/28/Seed-Selector-Jatekszabalyzat-2024.pdf`
  },
  {
    country: 'Romania',
    countryCode: 'RO',
    countryId: 'ROM',
    termsConditionURL: `${syngentaURL}.ro/node/691`,
    privacyPolicy: `${syngentaURL}.ro/declaratie-de-confidentialitate`,
    cookiePolicyURL: `${syngentaURL}.ro/politica-de-cookie-uri`,
    priceDrawRulesPdf: '',
  },
  {
    country: 'Poland',
    countryCode: 'PL',
    countryId: 'POL',
    termsConditionURL: `${syngentaURL}.pl/cropwise-terms`,
    privacyPolicy: `${syngentaURL}.pl/cropwise-privacy-notice`,
    cookiePolicyURL: `${syngentaURL}.pl/polityka-cookies`,
    priceDrawRulesPdf: '',
  },
  {
    country: CropConstants.Bulgaria,
    countryCode: 'BG',
    countryId: CropConstants.BULGARIA_CODE,
    termsConditionURL: `${syngentaURL}.bg/usloviya-za-polzvane-na-internet-stranicata`,
    privacyPolicy: `${syngentaURL}.bg/deklaraciya-za-poveritelnost`,
    cookiePolicyURL: ``,
    priceDrawRulesPdf: '',
  },
  {
    country: 'Germany',
    countryCode: 'DE',
    countryId: 'DEU',
    termsConditionURL: `${syngentaURL}.de/unternehmen/datenschutzerklaerung-cropwise`,
    privacyPolicy: `${syngentaURL}.de/unternehmen/nutzungsbedingungen-cropwise`,
    cookiePolicyURL: `${syngentaURL}.de/unternehmen/cookies`,
    priceDrawRulesPdf: `${syngentaURL}.de/teilnahmebedingungen-cropwise-seed-selector-gewinnspiel`,
  },
  {
    country: 'Ukraine',
    countryCode: 'UA',
    countryId: 'UKR',
    termsConditionURL: `${syngentaURL}.ua/politiki`,
    privacyPolicy: `${syngentaURL}.ua/politika-konfidenciynosti`,
    cookiePolicyURL: ``,
    priceDrawRulesPdf: '',
  },
  {
    country: CropConstants.SOUTH_AFRICA,
    countryCode: 'ZA',
    countryId: CropConstants.SOUTH_AFRICA_CODE,
    termsConditionURL: `${syngentaURL}.co.za/terms-conditions`,
    privacyPolicy: `${syngentaURL}.co.za/privacy-statement`,
    cookiePolicyURL: ``,
    priceDrawRulesPdf: '',
  },
];
export const getUrl = (countryCode: string): TermsConditionsPrivacyPolicy | undefined => {
  return TermsConditionPrivacyPolicyUrls.find(
    (item: TermsConditionsPrivacyPolicy) =>
      item.country.toLocaleLowerCase() === countryCode.toLocaleLowerCase() ||
      item.countryCode.toLocaleLowerCase() === countryCode.toLocaleLowerCase() ||
      item.countryId.toLocaleLowerCase() === countryCode.toLocaleLowerCase()
  );
};
